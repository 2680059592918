import { FieldHookConfig, useField } from 'formik';
import { Checkbox, Text, FormErrorMessage } from '@chakra-ui/react';
import theme from 'src/common/styles/CustomTheme';

const HackCheckbox = ({ children, ...props }: FieldHookConfig<any> & any) => {
  const [field, meta] = useField({ ...props, type: 'checkbox' });
  const error = meta.touched && meta.error;

  return (
    <div>
      <Checkbox
        {...field}
        {...props}
        size={'lg'}
        borderColor={error ? 'red.500' : theme.colors.primary}
        isChecked={field.value}
      >
        <Text textColor={error ? 'red' : 'white'} fontSize={'large'} opacity={'80%'}>
          {children}
        </Text>
      </Checkbox>
      {error && (
        <Text
          // Text values
          textColor={'red.500'}
          fontSize="0.9rem"
          // Dimension values
          marginTop="2px"
        >
          {meta.error}
        </Text>
      )}
    </div>
  );
};

export default HackCheckbox;
