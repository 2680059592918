import React from 'react';
import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  VStack,
} from '@chakra-ui/react';

import Button from 'src/common/components/Button/Button';
import LoginApi from 'src/common/services/LoginApi';
import { useMutation } from 'react-query';
import { MenuProfile } from './components/MenuProfile';
import { Base } from './components/Base';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';

export function ForgotPassword() {
  const [teamName, setteamName] = React.useState('' as string);
  const [profile, setProfile] = React.useState('' as string);

  const [teamNameError, setteamNameError] = React.useState('' as string);
  const [profileError, setProfileError] = React.useState('' as string);

  const nav = useNavigate();

  const useLogin = useMutation({
    mutationFn: () => LoginApi.ForgotPassword(teamName, profile),
    onSuccess: res => {
      if (res.status === 200) {
        Swal.fire({
          icon: 'success',
          background: '#F5F5F5',
          title: 'Ai primit link-ul pentru schimbare de parolă pe email!',
          showConfirmButton: true,
        }).then(() => {
          nav('/login');
        });
      } else {
        console.log('Login failed', res);
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          background: '#F5F5F5',
          text: `${res?.data || 'A picat satelitul, încearcă din nou mai târziu!'}`,
          showConfirmButton: true,
        });
      }
    },
  });

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (validateInputs()) {
      useLogin.mutate();
    }
  };

  const validateInputs = () => {
    let isValid = true;
    setteamNameError('');
    setProfileError('');
    if (!teamName) {
      setteamNameError('Nu ai introdus un email');
      isValid = false;
    }
    if (!profile) {
      setProfileError('Profilul este obligatoriu');
      isValid = false;
    }
    return isValid;
  };

  const handleProfileChange = (option: string) => {
    setProfile(option);
    setProfileError('');
  };

  return (
    <Base>
      <form style={{ width: '100%' }} onSubmit={handleSubmit}>
        <VStack spacing={{ base: 4, md: 6 }}>
          <FormControl isInvalid={teamNameError ? true : false}>
            <FormLabel fontWeight={700} color={'gray'}>
              Email
            </FormLabel>
            <Input
              value={teamName}
              onChange={e => setteamName(e.target.value)}
              name="email"
              type="email"
              border={'2px solid #03A9F4'}
              borderRadius={10}
              height={'3rem'}
              fontSize={'1.3rem'}
              _hover={{ borderColor: '#03A9F4' }}
            />
            <FormErrorMessage>{teamNameError}</FormErrorMessage>
          </FormControl>

          <Flex w={'100%'} justifyContent={'space-between'} mt={'0.5rem'}>
            <Button
              text={'Reseteaza parola'}
              type="submit"
              mx={'auto'}
              bgColor={'#03A9F4'}
              w={'13rem'}
              h={{ base: '3rem', md: '4rem' }}
              borderRadius={10}
              color={'white'}
              _hover={{ bgColor: '#03A9F4' }}
            />
          </Flex>

          <Box
            pos={'relative'}
            mt={{ base: '0.5rem', md: 'none' }}
            h={{ base: '12rem', lg: '14rem' }}
          >
            <MenuProfile
              profile={profile}
              profileError={profileError}
              handleProfileChange={handleProfileChange}
            />
          </Box>
        </VStack>
      </form>
    </Base>
  );
}
