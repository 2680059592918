import { Flex, Heading, VStack } from '@chakra-ui/react';
import _7Days from './assets/7days.png';
import CARTOFISSERIE from './assets/cartofisserie.png';
import DORITOS from './assets/doritos.png';
import HOCHLAND from './assets/hochland.png';
import { PartnerCard } from '../../../../common/components/PartnerCard/PartnerCard';

const partners = [
  {
    fullName: '7 Days',
    imgLink: _7Days,
    link: 'https://snack7days.com/',
  },
  {
    fullName: 'Cartofisserie',
    imgLink: CARTOFISSERIE,
    link: 'https://cartofisserie.ro/',
  },
  {
    fullName: 'Doritos',
    imgLink: DORITOS,
    link: 'https://www.doritos.ro/',
  },
  {
    fullName: 'Hochland',
    imgLink: HOCHLAND,
    link: 'https://www.hochland.ro/',
  },
];

export const PartnerFood = () => {
  return (
    <VStack spacing={8}>
      <Heading alignSelf={'flex-start'} size="xl">
        Parteneri Food&Drinks
      </Heading>
      <Flex gap="1rem" flexWrap={'wrap'} justifyContent={'center'}>
        {partners.map((member, i) => (
          <PartnerCard
            fullName={member.fullName}
            imgLink={member.imgLink}
            link={member.link}
            key={i}
          />
        ))}
      </Flex>
    </VStack>
  );
};
