import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  Text,
  Box,
} from '@chakra-ui/react';
import theme from 'src/common/styles/CustomTheme';
import CustomButton from 'src/common/components/Button/Button';

const Dialog = (props: { text: string; canClick: boolean }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Button
        onClick={() => {
          props.canClick && onOpen();
        }}
      >
        Click ca să citești mai multe
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} scrollBehavior={'inside'} isCentered>
        <ModalOverlay />
        <ModalContent bg={theme.colors.background}>
          <ModalCloseButton />
          <ModalBody marginTop={4} bg={theme.colors.background}>
            <Text whiteSpace="pre-line">{props.text}</Text>
          </ModalBody>

          <ModalFooter>
            <CustomButton mr={3} onClick={onClose} bgColor="primary" text={'Închide'} />
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default Dialog;
