import { FORGOT_PASSWORD_URL, LOGIN_URL, RESET_PASSOWRD_URL, VERIFY_URL } from '../constants';
import Utils from '../utils';

const LoginApi = {
  Login: async (teamName: string, password: string, competition: string) => {
    const body = {
      teamName: teamName,
      password: password,
      competition: competition,
    };

    const res = await Utils.PostRequest(LOGIN_URL, body);

    // console.log("Login response: ", res);

    if (res.token) {
      localStorage.setItem('token', res.token);

      return {
        status: res.status,
        data: res.data,
      };
    } else {
      return {
        status: res.status,
        data: res.data,
      };
    }
  },

  Logout: async () => {
    localStorage.removeItem('token');
  },

  IsLoggedIn: async () => {
    const token = localStorage.getItem('token');

    if (token) {
      return true;
    } else {
      return false;
    }
  },

  ForgotPassword: async (email: string, competition: string) => {
    const body = {
      email: email,
      competition: competition,
    };

    const res = await Utils.PostRequest(FORGOT_PASSWORD_URL, body);

    // console.log("Forgot password response: ", res);

    return {
      status: res.status,
      data: res.data,
    };
  },

  Verify: async (token: string) => {
    const body = {
      token: token,
    };

    const res = await Utils.PostRequest(VERIFY_URL, body);

    return {
      status: res.status,
      data: res.data,
    };
  },

  ResetPassword: async (password: string, token: string) => {
    const body = {
      password: password,
      token: token,
    };

    const res = await Utils.PostRequest(RESET_PASSOWRD_URL, body);

    return {
      status: res.status,
      data: res.data,
    };
  },
};

export default LoginApi;
