import { ChevronDownIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { Accordion, AccordionItem, AccordionButton, AccordionPanel, Box } from '@chakra-ui/react';
import { Dispatch, SetStateAction } from 'react';

interface FAQData {
  question: string;
  answer: string;
}

const iconProps = { boxSize: 6, color: 'hackathon' };

export function FAQAccordion({
  data,
  indexState,
}: {
  data: FAQData[];
  indexState: [number, Dispatch<SetStateAction<number>>];
}) {
  const [index, setIndex] = indexState;
  return (
    <Accordion
      allowToggle
      w="100%"
      index={index}
      onChange={e => {
        setIndex(e as number);
      }}
    >
      {data.map((faq, index) => (
        <AccordionItem key={index} borderColor={'white'}>
          {({ isExpanded }) => (
            <>
              <AccordionButton>
                {!isExpanded ? (
                  <ChevronRightIcon {...iconProps} />
                ) : (
                  <ChevronDownIcon {...iconProps} />
                )}

                <Box as="span" flex="1" textAlign="left">
                  {faq.question}
                </Box>
              </AccordionButton>
              <AccordionPanel mx={6} pb={4} textColor={'#ABABAB'}>
                {faq.answer}
              </AccordionPanel>
            </>
          )}
        </AccordionItem>
      ))}
    </Accordion>
  );
}
