import { Image, Flex, HStack, Link, Box, Show, VStack, Button } from '@chakra-ui/react';
import Logo from '../../assets/hackitall.png';
import MyDrawer from './Drawer';
import Utils from 'src/common/utils';
import { TEST_DATE } from 'src/common/constants';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const navList = [
  { name: 'Despre', link: '/#about' },
  { name: 'Probe', link: '/#contests' },
  { name: 'FAQ', link: '/#faq' },
  { name: 'Sponsori', link: '/#sponsors' },
  { name: 'Media', link: '/#media' },
  { name: 'Food&Drinks', link: '/#food&drinks' },
  { name: 'Contact', link: '/#contact' },
  { name: 'Echipă', link: '/#team' },
];

const navList2 = [
  { name: 'Despre', link: '/#about' },
  { name: 'Probe', link: '/#contests' },
  { name: 'FAQ', link: '/#faq' },
  { name: 'Sponsori', link: '/#sponsors' },
  { name: 'Media', link: '/#media' },
  { name: 'Food&Drinks', link: '/#food&drinks' },
  { name: 'Contact', link: '/#contact' },
  { name: 'Echipă', link: '/#team' },

  {
    name: 'Profile',
    link: '/team-profile',
  },
  {
    name: 'Log Out',
  },
];

// const checkDateRegister: boolean = Utils.CheckDate(
//   process.env.REACT_APP_REGISTER_DATE?.toString() || TEST_DATE,
// );

// // if the registration date has passed, the login button will be displayed
// if (checkDateRegister) {
//   const sponsorNav = { name: 'Sponsori', link: '/#sponsors' };
//   const aux = navList[3];
//   navList[3] = sponsorNav;
//   navList.push(aux);
//   navList.push({ name: 'Logare', link: '/login' });
//   navList.push({ name: 'Înregistrare', link: '/contest' });
// }

const linkStyle = {
  textDecoration: 'none',
  fontWeight: 'bold',
  fontSize: '1.45rem',
};

const Navbar = () => {
  const [isLogged, setIsLogged] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem('token')) {
      setIsLogged(true);
    }
  }, []);
  return (
    <VStack
      as={'header'}
      position={'fixed'}
      w={'100%'}
      spacing={0}
      backgroundColor={'background'}
      zIndex={1}
    >
      <Flex w="100%" px="6" py="5" align="center" justify="space-between">
        <Link style={linkStyle} href="/#header">
          <Image src={Logo} fit="contain" maxW="auto" maxH="35px" />
        </Link>
        <Show breakpoint="(min-width: 860px)">
          <HStack as="nav" spacing={{ base: 3, xl: 7 }} marginLeft={2}>
            {(isLogged ? navList2 : navList).map((item, index) =>
              item.name === 'Log Out' ? (
                <Button
                  key={index}
                  fontSize={{ base: 'lg' }}
                  _hover={{ textDecor: 'none' }}
                  fontWeight={'400'}
                  onClick={() => {
                    localStorage.removeItem('token');
                    navigate('/');
                    window.location.reload();
                  }}
                >
                  {item.name}
                </Button>
              ) : (
                <Link
                  key={index}
                  href={item.link}
                  _hover={{ textDecor: 'none' }}
                  fontSize={{ base: 'lg' }}
                >
                  {item.name}
                </Link>
              ),
            )}
          </HStack>
        </Show>
        <Show breakpoint="(max-width: 860px)">
          {isLogged ? (
            <MyDrawer navList={navList2} linkStyle={linkStyle} />
          ) : (
            <MyDrawer navList={navList} linkStyle={linkStyle} />
          )}
        </Show>
      </Flex>
      <Box
        w="100%"
        h="5px"
        bgGradient="linear(to-r, #03A9F4 24.38%, #7342FB 33.23%, #BB00FF 37.92%, #BB00FF 50.94%, #BB00FF 66.67%, #794FF6 71.25%, #00DFE6 83.75%)"
      />
    </VStack>
  );
};
export default Navbar;
