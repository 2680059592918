import { Heading, VStack } from '@chakra-ui/react';

interface TitleProps {
  contest: string;
  textColor: string;
  contestColor: string;
}

const Title: React.FC<TitleProps> = ({ contest, textColor, contestColor }) => (
  <VStack spacing={0}>
    <Heading
      // Text values
      textColor={textColor}
      textAlign="center"
      size={{ base: 'lg', md: 'xl', xl: '2xl' }}
    >
      Profilul echipei
    </Heading>
    <Heading
      // Text values
      textColor={contestColor}
      textAlign="center"
      size={{ base: 'xl', md: '2xl', xl: '3xl' }}
    >
      {contest}
    </Heading>
  </VStack>
);

export default Title;
