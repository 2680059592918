import { CONTACT_URL } from '../constants';
import Utils from '../utils';

export const ContactRequest = async (email: string, message: string) => {
  const body = {
    email,
    message,
  };

  return await Utils.PostRequest(CONTACT_URL, body);
};
