import { Center, Image, Text, VStack } from '@chakra-ui/react';
import HACK_LOGO from '../../../../common/assets/hackitall.png';
import '../../../../App.css';

export const IsComming = () => (
  <Center h="100vh" w="100vw">
    <VStack spacing={8} padding={8}>
      <Image src={HACK_LOGO} alt="HackITall logo" />
      <Text fontSize={{ base: '3xl', sm: '6xl' }} fontWeight="bold">
        Coming Soon
      </Text>
    </VStack>
  </Center>
);
