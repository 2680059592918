import axios from 'axios';

const Utils = {
  PostRequest: async (urlPath: string, data: any) => {
    const url = `${process.env.REACT_APP_API_URL}${urlPath}`;

    try {
      const response = await axios.post(url, data);

      // console.log("Response: ", response.data);

      return response;
    } catch (error: any) {
      if (error.response && error.response.data) {
        return error.response;
      } else {
        return error;
      }
    }
  },

  PatchRequest: async (urlPath: string, data: any, headers?: any) => {
    const url = `${process.env.REACT_APP_API_URL}${urlPath}`;

    try {
      const response = await axios.patch(url, data, { headers });

      // console.log("Response: ", response.data);

      return response;
    } catch (error: any) {
      if (error.response && error.response.data) {
        return error.response;
      } else {
        return error;
      }
    }
  },

  GetRequest: async (urlPath: string, headers?: any) => {
    const url = `${process.env.REACT_APP_API_URL}${urlPath}`;

    try {
      const response = await axios.get(url, { headers });

      // console.log("Response: ", response.data);

      return response;
    } catch (error: any) {
      if (error.response && error.response.data) {
        return error.response;
      } else {
        return error;
      }
    }
  },

  CheckDate: (date: string | number | Date) => {
    const targetDate = new Date(date);
    const currentDateRO = new Date().toLocaleString('en-US', { timeZone: 'Europe/Bucharest' });
    const targetDateROString = targetDate.toLocaleString('en-US', { timeZone: 'Europe/Bucharest' });

    if (targetDateROString <= currentDateRO) return true;
    return false;
  },
};

export default Utils;
