import JaneStreet from './assets/jane-street-bg.png';
import Bcr from './assets/bcr-bg.png';
import ING from './assets/ing-bg.png';
import PLAYTIKA from './assets/playtika.png';
import FINASTRA from './assets/finastra-bg.png';

export const sponsorsData = [
  {
    imageSrc: Bcr,
    tipSponsor: 'Main',
    tipEveniment: 'Hackathon',
    descriereSponsor: `Misiunea BCR este de a contribui la crearea de prosperitate pentru România, sprijinind șansele tuturor la o viață mai bună și susținându-le încrederea în forțele proprii și în capacitatea de a-și atinge potențialul. Pentru aceasta, BCR investește constant în acces sigur și facil la servicii financiare, banking pe înțelesul tuturor, consiliere financiară pe termen lung și produse care respectă potențialul fiecărui client. 
      
      BCR a creat cel mai amplu și consistent program de educație financiară din România, “Școala de Bani”, prin cursuri pentru adulți și tineri, o expoziție mobilă de educație financiară pentru copii și o platformă digitală dedicată, toate acestea prin implicarea a peste 1.000 de profesori de educație financiară, experți ai BCR.`,
  },
  {
    imageSrc: JaneStreet,
    tipSponsor: 'Recruiter',
    tipEveniment: 'Hackathon',
    descriereSponsor: `Jane Street is a quantitative trading firm with offices worldwide. We hire smart, humble people who love to solve problems, build systems, and test theories. You’ll learn something new every day in our office—whether it’s connecting with a colleague to share perspectives, or participating in a talk, class, or game night. Our success is driven by our people and we never stop improving.
    
    We’re looking for the next wave of curious and passionate individuals to join our Jane Street Internship Program.
    
    Over the course of a Jane Street Internship, you’ll explore ways to approach and solve problems through challenging classes and interactive discussions, with endless opportunities to put those learnings to use. We offer opportunities in a variety of disciplines — quantitative trading, software engineering, quantitative research, business development, and more — which put you directly in the action, giving you the opportunity to collaborate and connect with Jane Streeters across teams and offices.
    
    Find out more at https://www.janestreet.com/`,
  },
  {
    imageSrc: ING,
    tipSponsor: 'Main',
    tipEveniment: 'Hackathon',
    descriereSponsor: `ING Hubs România oferă 130 de servicii în dezvoltarea software, managementul datelor, risc non-financiar și conformitate, audit și operațiuni de retail pentru 24 de unități ING din întreaga lume, cu ajutorul a peste 1700 de ingineri de performanță înaltă, profesioniști în risc și operațiuni.

    Fost cunoscut sub numele de ING Tech, începând cu 2022 oferim servicii fără granițe cu capacități la nivel de bancă sub numele de ING Hubs România și operăm din două locații: București și Cluj-Napoca.
    
    Am început în 2015 ca hub de dezvoltare software al ING, apoi ne-am extins treptat gama pentru a include mai multe servicii și competențe în tehnologie, risc non-financiar și conformitate, audit și operațiuni de retail.
    
    Capacitățile noastre tehnologice rămân nucleul afacerii noastre, cu peste 1500 de colegi activi în Managementul Datelor, Produse Globale, Canale de Interacțiune și Integrare, și Banking Central.
    
    În cadrul Grupului ING, Hub-urile acționează ca centre globale de capacități care promovează meșteșugul, investițiile tehnologice focalizate, automatizarea și activitățile operaționale la scară largă. Disciplinele inter-locatii și echipele multidisciplinare construiesc servicii integrate prin fuzionarea tehnologiei, proceselor și operațiunilor pentru o experiență îmbunătățită a clienților.`,
  },
  {
    imageSrc: PLAYTIKA,
    tipSponsor: 'Main',
    tipEveniment: 'Gamejam',
    descriereSponsor: `
    Playtika is more than just a mobile games company - it's a beacon of innovation and excellence in interactive entertainment. From our humble beginnings as an Israeli startup in 2010, with our scrappy resolve and cheetah-like instincts, we've grown to become an industry leader with more than millions of daily active users across our portfolio of games. 
    
    We are constantly pushing the boundaries of what's possible, delighting players with our award-winning and category-leading Casual and Social Casino-themed games like Bingo Blitz, Solitaire Grand Harvest and Slotomania. 
    
    In fact, we operate nine of the 100 top grossing titles in the US, and five of our games are category leaders. At the heart of our success is Playtika's unparalleled data-driven operational prowess, business discipline and commitment to excellence. 
   
    We are masters at operating and scaling games, leveraging the latest technology, such as AI and machine learning, to develop titles that captivate and inspire exploration, connectivity and fun. Through our intuitive gameplay mechanics, every aspect of our games is designed with one goal in mind - to provide our players with the best possible personalized experience.`,
  },
  {
    imageSrc: FINASTRA,
    tipSponsor: 'Recruiter',
    tipEveniment: 'Hackathon',
    descriereSponsor: `
    Finastra oferă cel mai cuprinzător portofoliu de soluții de creditare de la un capăt la altul de pe piață - acoperind creditarea sindicalizată, comercială, de consum și ipotecară.

    Oferim o experiență digitală consecventă și fără fricțiuni pentru împrumutat pentru o gamă variată de afaceri, corporații și consumatori, îmbunătățind în același timp integrarea clienților, crescând transparența și eficientizând operațiunile de back-office.
    
    Pe lângă reducerea complexității, costurilor și proceselor manuale, soluțiile Finastra oferă o singură platformă pentru a livra eficiență și un randament clar al investiției.
    `,
  },
];

export default sponsorsData;
