import { Flex, Heading, VStack } from '@chakra-ui/react';
import SponsorCard from './SponsorCard';
import sponsorsData from './SponsorsData';

const Sponsors = () => {
  return (
    <VStack>
      <Heading alignSelf={'flex-start'} size="xl">
        Sponsori
      </Heading>
      <Flex gap="1rem" flexWrap={'wrap'} justifyContent={'center'}>
        {sponsorsData.map((sponsor, id) => (
          <SponsorCard key={id} {...sponsor} />
        ))}
      </Flex>
    </VStack>
  );
};

export default Sponsors;
