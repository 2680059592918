import { Sponsor } from './sponsor';

const defaultSponsors = <Array<Array<Sponsor>>>[
  Array.from({ length: 2 }, (_, index) => ({ id: index + 1, name: `BLANK${index + 1}` })),
  [],
];
const defaultSponsorsQuantity = 2;
const defaultBoxesQuantity = 2;

// Interface with mandatory and optional fields
export interface SponsorPreferencesProps {
  sponsors: Array<Array<Sponsor>>;
  setSponsors: any;
  sponsorsQuantity?: number;
  messages?: Array<string>;
  boxesQuantity?: number;
  sponsorMinHeight?: number;
  sponsorMinWidth?: number;
  updateSponsors?: (sponsors: Array<Sponsor>) => void;
  setIsPreferencesChanged?: (isPreferencesChanged: boolean) => void;
  style?: React.CSSProperties;
  sponsorStyle?: React.CSSProperties;
  imageStyle?: React.CSSProperties;
}
// Optional fields are being set by default
const defaultProps: SponsorPreferencesProps = {
  sponsors: defaultSponsors,
  setSponsors: () => {
    return;
  },
  sponsorsQuantity: defaultSponsorsQuantity,
  boxesQuantity: defaultBoxesQuantity,
  sponsorMinHeight: 100,
  sponsorMinWidth: 150,
};
// Function to retrieve the optional fields
export const getSponsorPreferencesProps = (
  props: Partial<SponsorPreferencesProps>,
): SponsorPreferencesProps => ({
  // Mandatory
  ...defaultProps,
  // Optional
  ...props,
});
