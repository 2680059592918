import { useState, useEffect } from 'react';
import { AbsoluteCenter, Center, Image, Stack, Text } from '@chakra-ui/react';
import Logo_HackITall from '../../common/assets/logo_hack.svg';

function Timer(props: { deadline: string; text: string }) {
  const [hours, setHours] = useState('00');
  const [minutes, setMinutes] = useState('00');
  const [seconds, setSeconds] = useState('00');
  const [displayNow, setDisplayNow] = useState(true);

  function getFormatted(time: number) {
    return ('0' + time).slice(-2);
  }

  function getTime(deadline: string) {
    const time = Date.parse(deadline) - Date.now();

    if (time < 0) {
      setDisplayNow(false);
      return;
    }
    // setHours from days to hours
    const days = Math.floor(time / (1000 * 60 * 60 * 24));
    setHours(getFormatted(days * 24 + Math.floor((time / (1000 * 60 * 60)) % 24)));
    setMinutes(getFormatted(Math.floor((time / (1000 * 60)) % 60)));
    setSeconds(getFormatted(Math.floor((time / 1000) % 60)));
  }

  useEffect(() => {
    const interval = setInterval(() => getTime(props.deadline), 50);

    return () => clearInterval(interval);
  }, []);

  return (
    <AbsoluteCenter fontSize={{ base: '3rem', sm: '5rem', md: '10rem', lg: '18rem' }}>
      <Stack align={'center'}>
        <Image src={Logo_HackITall} width={'400px'} height={'auto'} />
        {displayNow ? (
          <>
            <Text>
              {hours}:{minutes}:{seconds}
            </Text>
          </>
        ) : (
          <Text fontSize={{ base: '2rem', sm: '3rem', md: '7rem', lg: '10rem' }}>STOP CODING</Text>
        )}
        <Text fontSize={{ base: '1.5rem', sm: '3rem', md: '6rem', lg: '9rem' }}>{props.text}</Text>
      </Stack>
    </AbsoluteCenter>
  );
}

export default Timer;
