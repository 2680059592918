import React from 'react';
import { DroppableAreaProps } from '../../interfaces/droppableAreaProps';
import { Droppable } from 'react-beautiful-dnd';
import Sponsor from './Sponsor';
import { Container, Text, VStack } from '@chakra-ui/react';
import theme from '../../styles/CustomTheme';

const PreferencesBox: React.FC<DroppableAreaProps> = ({
  index,
  droppableId,
  sponsors,
  message,
  sponsorsQuantity,
  sponsorMinHeight,
  sponsorMinWidth,
  sponsorStyle,
  imageStyle,
}) => {
  const borderDiameter = 1;

  return (
    <Droppable droppableId={droppableId}>
      {provided => (
        <Container>
          <Text
            fontSize="1rem"
            color={theme.colors.heading}
            // Align values
            textAlign="left"
          >
            {message}
          </Text>
          <VStack
            spacing={index == 0 ? 1 : 0}
            // Design values
            bg="transparent"
            border={
              index > 0
                ? `${borderDiameter}px solid var(--Culori-cb,` + theme.colors.hackathon + ')'
                : 'none'
            }
            borderRadius="1.25rem"
            // Dimension value
            minWidth={sponsorMinWidth + 2 * borderDiameter} // These 2 need to be set afte solving the main container size problem SO the boxes stays flat even when 0 sponsor cards are within it
            minHeight={
              sponsorMinHeight * sponsorsQuantity + 2 * borderDiameter + (index == 0 ? 2 : 0)
            }
            // Droppable values
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            {sponsors?.map((sponsor, index) => (
              <Sponsor
                key={sponsor.id}
                index={index}
                sponsors={sponsors}
                sponsor={sponsor}
                sponsorMinHeight={sponsorMinHeight}
                sponsorMinWidth={sponsorMinWidth}
                boxStyle={sponsorStyle}
                imageStyle={imageStyle}
              />
            ))}
            {provided.placeholder}
          </VStack>
        </Container>
      )}
    </Droppable>
  );
};

export default PreferencesBox;
