import React from 'react';
import {
  Link,
  Flex,
  Box,
  Drawer,
  DrawerBody,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
} from '@chakra-ui/react';

export const MenuDrawer = (props: {
  profileOptions: string[];
  handleChange: (e: string) => void;
  isOpen: boolean;
  onClose: () => void;
}) => {
  const { isOpen, onClose, handleChange, profileOptions } = props;
  return (
    <>
      <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />

          <DrawerBody bg="background">
            <Box h="2.5rem" />
            <Flex direction="column" gap="24px" align="center">
              {profileOptions.map((item, index) => (
                <Link
                  key={index}
                  fontWeight={600}
                  fontSize={'1.45rem'}
                  textDecoration={'none'}
                  _hover={{ textDecor: 'none' }}
                  onClick={() => {
                    onClose(), handleChange(item);
                  }}
                >
                  Profil {item}
                </Link>
              ))}
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};
