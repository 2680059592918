import { Icon, createIcon } from '@chakra-ui/react';

interface XProps {
  size: string;
  color: string;
}

const X: React.FC<XProps> = ({ size, color }) => {
  const CustomIcon = createIcon({
    displayName: 'CustomIcon',
    viewBox: '0 0 48 48',
    path: (
      <path
        fill={color}
        d="M29.6346 23.9966L46.8179 6.85072C47.5704 6.09813 47.9932 5.07739 47.9932 4.01306C47.9932 2.94873 47.5704 1.92799 46.8179 1.1754C46.0655 0.422804 45.0449 0 43.9807 0C42.9165 0 41.8959 0.422804 41.1434 1.1754L24 18.3612L6.85657 1.1754C6.10408 0.422804 5.08349 -7.92987e-09 4.01931 0C2.95513 7.92988e-09 1.93454 0.422804 1.18205 1.1754C0.429563 1.92799 0.00681982 2.94873 0.00681981 4.01306C0.0068198 5.07739 0.429563 6.09813 1.18205 6.85072L18.3654 23.9966L1.18205 41.1425C0.807499 41.514 0.51021 41.956 0.307332 42.4431C0.104453 42.9301 0 43.4525 0 43.9801C0 44.5077 0.104453 45.0301 0.307332 45.5172C0.51021 46.0042 0.807499 46.4462 1.18205 46.8178C1.55354 47.1924 1.99552 47.4897 2.48249 47.6926C2.96945 47.8955 3.49177 48 4.01931 48C4.54685 48 5.06916 47.8955 5.55613 47.6926C6.0431 47.4897 6.48507 47.1924 6.85657 46.8178L24 29.6319L41.1434 46.8178C41.5149 47.1924 41.9569 47.4897 42.4439 47.6926C42.9308 47.8955 43.4532 48 43.9807 48C44.5082 48 45.0305 47.8955 45.5175 47.6926C46.0045 47.4897 46.4465 47.1924 46.8179 46.8178C47.1925 46.4462 47.4898 46.0042 47.6927 45.5172C47.8955 45.0301 48 44.5077 48 43.9801C48 43.4525 47.8955 42.9301 47.6927 42.4431C47.4898 41.956 47.1925 41.514 46.8179 41.1425L29.6346 23.9966Z"
      />
    ),
  });

  return <Icon as={CustomIcon} boxSize={size} />;
};

export default X;
