import { FormControl, FormErrorMessage, FormLabel, Input, Textarea } from '@chakra-ui/react';

import Button from 'src/common/components/Button/Button';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { ContactRequest } from 'src/common/services/ContactApi';
import Swal from 'sweetalert2';

type formValues = {
  email: string;
  message: string;
};

export function ContactForm() {
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<formValues>();

  const useContact = useMutation({
    mutationFn: (values: formValues) => ContactRequest(values.email, values.message),
    onSuccess: res => {
      if (res.status === 200) {
        Swal.fire({
          icon: 'success',
          background: '#F5F5F5',
          title: 'Mesajul a fost trimis cu succes!',
          showConfirmButton: false,
          timer: 1500,
        });
        reset();
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          background: '#F5F5F5',
          text: `${res?.data?.message || 'A picat satelitul, încearcă din nou mai târziu!'}`,
          showConfirmButton: true,
        });
      }
    },
  });

  return (
    <form
      onSubmit={handleSubmit(values => {
        useContact.mutate(values);
      })}
    >
      <FormControl isInvalid={!!errors.email}>
        <FormLabel fontSize="1.2rem" color="#0977BE" textDecoration="underline">
          E-mail
        </FormLabel>
        <Input
          id="email"
          {...register('email', {
            required: 'E-mail-ul este obligatoriu',
            pattern: {
              value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/,
              message: 'E-mail-ul introdus nu este valid',
            },
          })}
          _placeholder={{ color: 'grey' }}
          placeholder="E-mail-ul la care dorești să fii contactat"
          border="1px"
          borderColor="#0977BE"
          p="4"
          borderRadius="10px"
        />
        <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
      </FormControl>

      <FormControl my={4} isInvalid={!!errors.message}>
        <FormLabel fontSize="1.2rem" color="#0977BE" textDecoration="underline">
          Mesaj
        </FormLabel>
        <Textarea
          id="message"
          _placeholder={{ color: 'grey' }}
          placeholder="Mesajul pe care dorești să îl transmiți..."
          css={{ height: '150px' }}
          border="1px"
          borderColor="#0977BE"
          p="4"
          borderRadius="10px"
          {...register('message', {
            required: 'Mesajul este obligatoriu',
          })}
        />
        <FormErrorMessage>{errors.message?.message}</FormErrorMessage>
      </FormControl>
      <Button type="submit" isLoading={isSubmitting} bgColor={'primary'} text={'Trimite'} />
    </form>
  );
}
