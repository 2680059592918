import { Center, Image, Spinner, Text, VStack } from '@chakra-ui/react';
import HACK_LOGO from '../common/assets/logo_hack.svg';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { VERIFICATION_URL } from 'src/common/constants';
import axios from 'axios';

const Verification = () => {
  const nav = useNavigate();
  const { token } = useParams();

  const { isLoading, isError } = useQuery({
    queryKey: 'profile',
    retry: false,
    queryFn: async () => {
      const url = `${process.env.REACT_APP_API_URL}${VERIFICATION_URL}`;
      return await axios.post(url, { token });
    },
    onSuccess: () => {
      Swal.fire({
        title: 'Email-ul a fost validat.',
        showConfirmButton: true,
        preConfirm: () => nav('/login'),
      });
    },
  });

  let message = '';
  if (isLoading) message = 'Se verifică adresa de email.';
  else if (isError)
    message = 'A apărut o eroare in verificarea contului.\nVă rugăm contactaţi organizarea.';

  return (
    <Center h="85vh" w="100%">
      <VStack spacing={8} padding={8}>
        <Image src={HACK_LOGO} alt="HackITall logo" />
        <VStack spacing={4}>
          <Text align={'center'} fontSize={{ base: '3xl', sm: '5xl' }} fontWeight="bold">
            {message}
          </Text>
          {isLoading && <Spinner size="lg" />}
        </VStack>
      </VStack>
    </Center>
  );
};

export default Verification;
