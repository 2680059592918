import React from 'react';
import { SponsorProps } from '../../interfaces/sponsorProps';
import { Draggable } from 'react-beautiful-dnd';
import { Center, Image } from '@chakra-ui/react';
import ReactDOM from 'react-dom';

// Using portals to render the dragging without interfering with other components in the tree
//aka. puts the draggable item in the top of the tree
const portal = document.createElement('div');
portal.classList.add('my-portal');
document.body.appendChild(portal);

const Sponsor: React.FC<SponsorProps> = ({
  index,
  sponsor,
  sponsorMinHeight,
  sponsorMinWidth,
  boxStyle,
  imageStyle,
}) => {
  return (
    <Draggable key={index} index={index} draggableId={sponsor.id.toString()}>
      {(provided, snapshot) => {
        const child = (
          <form {...provided.dragHandleProps} {...provided.draggableProps} ref={provided.innerRef}>
            <Center
              // Design values
              bg={'transparent'}
              background={sponsor.background}
              borderRadius="1rem"
              // Dimension values
              minWidth={sponsorMinWidth}
              minHeight={sponsorMinHeight}
              // CSS values
              style={boxStyle}
            >
              <Image
                src={sponsor.imageSRC}
                borderRadius="1rem"
                height={sponsorMinHeight}
                width={sponsorMinWidth}
                style={imageStyle}
              />
            </Center>
          </form>
        );

        // Item is not dragged return the child(normal rendering in component's parent hierarchy)
        const usePortal: boolean = snapshot.isDragging;
        if (!usePortal) {
          return child;
        }

        // Item is dragged return the child(detached from normal parent hierarchy and rendered at the top-level in DOM)
        return ReactDOM.createPortal(child, portal);
      }}
    </Draggable>
  );
};

export default Sponsor;
