import { Center, Image, Text, VStack } from '@chakra-ui/react';
import HACK_LOGO from '../common/assets/logo_hack.svg';

export const NotFound = () => (
  <Center h="85vh" w="100%">
    <VStack spacing={8} padding={8}>
      <Image src={HACK_LOGO} alt="HackITall logo" />
      <VStack spacing={0}>
        <Text noOfLines={1} fontSize={{ base: '3xl', sm: '6xl' }} fontWeight="bold">
          Se pare ca te-ai pierdut...
        </Text>
        <Text noOfLines={1} fontSize={{ base: '3xl', sm: '6xl' }} fontWeight="bold">
          Ne cerem scuze dar aceasta pagina nu exista!
        </Text>
      </VStack>
    </VStack>
  </Center>
);
