import React from 'react';
import { Flex, FormControl, FormErrorMessage, FormLabel, Input, VStack } from '@chakra-ui/react';

import Button from 'src/common/components/Button/Button';
import LoginApi from 'src/common/services/LoginApi';
import { useMutation } from 'react-query';
import { Base } from './components/Base';
import Swal from 'sweetalert2';
import { useNavigate, useParams } from 'react-router-dom';

export function ResetPassword() {
  const [password, setPassword] = React.useState('' as string);
  const [passwordError, setPasswordError] = React.useState('' as string);

  const [confirmPassword, setConfirmPassword] = React.useState('' as string);
  const [confirmPasswordError, setConfirmPasswordError] = React.useState('' as string);

  const nav = useNavigate();
  const { token } = useParams();

  const useLogin = useMutation({
    mutationFn: () => LoginApi.ResetPassword(password, token as string),
    onSuccess: res => {
      if (res.status === 200) {
        Swal.fire({
          icon: 'success',
          background: '#F5F5F5',
          title: 'Ai schimbat parola cu succes!',
          showConfirmButton: true,
        }).then(() => {
          nav('/login');
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          background: '#F5F5F5',
          text: `${res?.data?.message || 'A picat satelitul, încearcă din nou mai târziu!'}`,
          showConfirmButton: true,
        });
      }
    },
  });

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (validateInputs()) {
      useLogin.mutate();
    }
  };

  const validateInputs = () => {
    let isValid = true;

    setPasswordError('');
    setConfirmPasswordError('');

    if (!password) {
      setPasswordError('Parola este obligatorie');
      isValid = false;
    }

    if (!confirmPassword) {
      setConfirmPasswordError('Confirmarea parolei este obligatorie');
      isValid = false;
    }

    if (password !== confirmPassword) {
      setConfirmPasswordError('Parolele nu coincid');
      isValid = false;
    }
    return isValid;
  };
  return (
    <Base>
      <form style={{ width: '100%' }} onSubmit={handleSubmit}>
        <VStack spacing={{ base: 4, md: 6 }}>
          <FormControl isInvalid={passwordError ? true : false}>
            <FormLabel fontWeight={700} color={'gray'}>
              Parolă
            </FormLabel>
            <Input
              value={password}
              onChange={e => setPassword(e.target.value)}
              name="password"
              type="password"
              border={'2px solid #03A9F4'}
              borderRadius={10}
              height={'3rem'}
              fontSize={'1.3rem'}
              _hover={{ borderColor: '#03A9F4' }}
            />
            <FormErrorMessage>{passwordError}</FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={confirmPasswordError ? true : false}>
            <FormLabel fontWeight={700} color={'gray'}>
              Confirmare parolă
            </FormLabel>
            <Input
              value={confirmPassword}
              onChange={e => setConfirmPassword(e.target.value)}
              name="confirmPassword"
              type="password"
              border={'2px solid #03A9F4'}
              borderRadius={10}
              height={'3rem'}
              fontSize={'1.3rem'}
              _hover={{ borderColor: '#03A9F4' }}
            />
            <FormErrorMessage>{confirmPasswordError}</FormErrorMessage>
          </FormControl>

          <Flex w={'100%'} justifyContent={'space-between'} mt={'0.5rem'}>
            <Button
              text={'Reseteaza parola'}
              type="submit"
              mx={'auto'}
              bgColor={'#03A9F4'}
              w={'13rem'}
              h={{ base: '3rem', md: '4rem' }}
              borderRadius={10}
              color={'white'}
              _hover={{ bgColor: '#03A9F4' }}
            />
          </Flex>
        </VStack>
      </form>
    </Base>
  );
}
