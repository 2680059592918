import React from 'react';
import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Link,
  VStack,
} from '@chakra-ui/react';

import Button from 'src/common/components/Button/Button';

import LoginApi from 'src/common/services/LoginApi';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { MenuProfile } from './components/MenuProfile';
import { Base } from './components/Base';
import Swal from 'sweetalert2';

export function Login() {
  const navigate = useNavigate();

  const [teamName, setteamName] = React.useState('' as string);
  const [password, setPassword] = React.useState('' as string);
  const [profile, setProfile] = React.useState('' as string);

  const [teamNameError, setteamNameError] = React.useState('' as string);
  const [passwordError, setPasswordError] = React.useState('' as string);
  const [profileError, setProfileError] = React.useState('' as string);

  const useLogin = useMutation({
    mutationFn: () => LoginApi.Login(teamName, password, profile),
    onSuccess: res => {
      if (res.status === 200) {
        // console.log('Login successful');
        localStorage.setItem('token', res.data.token);
        navigate('/team-profile');
        window.location.reload();
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          background: '#F5F5F5',
          text: `${res?.data || 'A picat satelitul, încearcă din nou mai târziu!'}`,
          showConfirmButton: true,
        });
      }
    },
  });

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (validateInputs()) {
      useLogin.mutate();
    }
  };

  const validateInputs = () => {
    let isValid = true;
    setteamNameError('');
    setPasswordError('');
    setProfileError('');
    if (!teamName) {
      setteamNameError('Denumirea echipei este obligatorie');
      isValid = false;
    }
    if (!password) {
      setPasswordError('Parola este obligatorie');
      isValid = false;
    }
    if (!profile) {
      setProfileError('Profilul este obligatoriu');
      isValid = false;
    }
    return isValid;
  };

  const handleProfileChange = (option: string) => {
    setProfile(option);
    setProfileError('');
  };

  return (
    <Base>
      <form onSubmit={handleSubmit}>
        <VStack spacing={{ base: 3, md: 6 }}>
          <FormControl isInvalid={teamNameError ? true : false}>
            <FormLabel fontWeight={700} color={'gray'}>
              Denumire echipă
            </FormLabel>
            <Input
              value={teamName}
              onChange={e => setteamName(e.target.value)}
              name="teamName"
              type="text"
              border={'2px solid #03A9F4'}
              borderRadius={10}
              height={'3rem'}
              fontSize={'1.3rem'}
              _hover={{ borderColor: '#03A9F4' }}
            />
            <FormErrorMessage>{teamNameError}</FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={passwordError ? true : false}>
            <FormLabel fontWeight={700} color={'gray'}>
              Parolă
            </FormLabel>
            <Input
              value={password}
              onChange={e => setPassword(e.target.value)}
              isRequired
              name="password"
              type="password"
              border={'2px solid #03A9F4'}
              borderRadius={10}
              height={'3rem'}
              fontSize={'1.3rem'}
              _hover={{ borderColor: '#03A9F4' }}
            />
            <FormErrorMessage>{passwordError}</FormErrorMessage>
          </FormControl>

          <Flex justifyContent={'end'} mt={'0.5rem'}>
            <Link
              href={'/forgotpassword'}
              fontWeight={700}
              color={'gray'}
              textDecoration={'underline'}
              textDecorationThickness={'1px'}
            >
              Mi-am uitat parola
            </Link>
          </Flex>

          <Flex justifyContent={'space-between'}>
            <Link href="/contest">
              <Button
                text={'Înscrie-te'}
                bgColor={''}
                variant={'outline'}
                w={{ base: '8rem', md: '13rem' }}
                h={{ base: '3rem', md: '4rem' }}
                borderColor={'#03A9F4'}
                borderRadius={10}
                color={'white'}
                _hover={{ bgColor: '#03A9F4' }}
              />
            </Link>
            <Button
              text={'Logare'}
              type="submit"
              bgColor={'#03A9F4'}
              w={{ base: '8rem', md: '13rem' }}
              h={{ base: '3rem', md: '4rem' }}
              ml={{ base: '15px' }}
              borderRadius={10}
              color={'white'}
              _hover={{ bgColor: '#03A9F4' }}
            />
          </Flex>

          <Box
            pos={'relative'}
            mt={{ base: '0.5rem', md: 'none' }}
            h={{ base: '12rem', lg: '14rem' }}
          >
            <MenuProfile
              profile={profile}
              profileError={profileError}
              handleProfileChange={handleProfileChange}
            />
          </Box>
        </VStack>
      </form>
    </Base>
  );
}
