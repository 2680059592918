import { VStack, Image, Link } from '@chakra-ui/react';

export interface PartnerCardProps {
  fullName: string;
  imgLink: string;
  link: string;
}

export const PartnerCard = ({ fullName, imgLink, link }: PartnerCardProps) => {
  return (
    <VStack spacing={2} w={300}>
      <Link isExternal href={link}>
        <Image
          src={imgLink}
          w="100%"
          alt={`${fullName} logo`}
          borderRadius="16px"
          objectFit="cover"
          aspectRatio={16 / 9}
        />
      </Link>
    </VStack>
  );
};
