import React, { useState } from 'react';
import { Sponsor } from '../../interfaces/sponsor';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import DroppableArea from './DroppableArea';
import {
  SponsorPreferencesProps,
  getSponsorPreferencesProps,
} from '../../interfaces/sponsorPreferencesProps';
import { HStack } from '@chakra-ui/react';

const SponsorPreferences: React.FC<SponsorPreferencesProps> = props => {
  // Get the mandatory + optional fields
  const sponsorPreferencesProps = getSponsorPreferencesProps(props);
  const {
    boxesQuantity,
    sponsorsQuantity,
    sponsors,
    setSponsors,
    messages,
    sponsorMinHeight,
    sponsorMinWidth,
    updateSponsors,
    setIsPreferencesChanged,
    style, // CSS props
    sponsorStyle, // CSS props
    imageStyle, // CSS props
  } = sponsorPreferencesProps;

  const height = sponsorMinHeight! * sponsorsQuantity!;

  // 'sponsorCards' matrix of 'Sponsor' from which can be choose for sponsor contest preferences
  // selection every line of the matrix represents a column where sponsors can sit
  // 'setSponsorCards' function to set the array
  const sponsorCards = sponsors;
  const setSponsorCards = setSponsors;
  // const [sponsorCards, setSponsorCards] = useState<Array<Array<Sponsor>>>(sponsors);

  const onDragEnd = (result: DropResult) => {
    const { destination, source } = result;

    if (!destination || !source) {
      return;
    } else if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    const position = sponsorCards;

    // Source Logic
    for (let i = 0; i < boxesQuantity!; i++) {
      if (source.droppableId === `Box#${i}`) {
        const add = position[i][source.index];
        position[i].splice(source.index, 1);

        // Destination logic
        for (let j = 0; j < boxesQuantity!; j++) {
          if (destination.droppableId === `Box#${j}`) {
            position[j].splice(destination.index, 0, add);
            break;
          }
        }
        break;
      }
    }

    setIsPreferencesChanged?.(true);
    updateSponsors?.([...position[1]]);
    setSponsorCards([...position]);
  };

  // Dynamically generate DroppableArea components based on boxesQuantity
  const droppableAreas = Array.from({ length: boxesQuantity! }, (_, index) => (
    <DroppableArea
      key={index}
      index={index}
      droppableId={`Box#${index}`}
      sponsors={sponsorCards[index]}
      sponsorsQuantity={sponsorsQuantity!}
      sponsorMinHeight={sponsorMinHeight!}
      sponsorMinWidth={sponsorMinWidth!}
      message={messages ? messages[index] : ''}
      sponsorStyle={sponsorStyle}
      imageStyle={imageStyle}
    />
  ));

  return (
    <HStack spacing={3} display="flex" height={height} style={style}>
      <DragDropContext onDragEnd={onDragEnd}>{droppableAreas}</DragDropContext>
    </HStack>
  );
};

export default SponsorPreferences;
