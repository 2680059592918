import { ChakraProvider, Box, Flex } from '@chakra-ui/react';
import './App.css';
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import { Login } from './pages/Login/Login';
import { ForgotPassword } from './pages/Login/ForgotPassword';
import TeamRegister from './pages/TeamRegister/TeamRegister';
import { QueryClient, QueryClientProvider } from 'react-query';
import Navbar from './common/components/Navbar/Navbar';
import Footer from './common/components/Footer/Footer';

import Utils from './common/utils';

const queryClient = new QueryClient();
import theme from './common/styles/CustomTheme';
import Fonts from './common/styles/fonts';
import Home from './pages/Home/Home';
import ContestChoice from './pages/ContestChoice/ContestChoice';

import { IsComming } from './pages/Home/components/IsComming/IsComming';
import { NotFound } from './pages/NotFound';
import { TEST_DATE } from './common/constants';
import TeamProfile from './pages/TeamProfile/TeamProfile';
import { ResetPassword } from './pages/Login/ResetPassword';
import Verification from './pages/Verification';
import Timer from './pages/Timer/Timer';
import { useEffect } from 'react';

const checkDateDisplay: boolean = Utils.CheckDate(
  process.env.REACT_APP_DISPLAY_DATE?.toString() || TEST_DATE,
);

const checkDateRegister: boolean = Utils.CheckDate(
  process.env.REACT_APP_REGISTER_DATE?.toString() || TEST_DATE,
);

export const App = () => {
  const url = window.location.href;

  return (
    <QueryClientProvider client={queryClient}>
      <ChakraProvider theme={theme}>
        <Fonts />
        {checkDateDisplay ? (
          <>
            <BrowserRouter>
              <Flex minHeight={'99vh'} flexDirection={'column'} justifyContent={'space-between'}>
                {!url.includes('countdown') && <Navbar />}
                <Box h={checkDateRegister ? '90px' : '150px'} />
                <Routes>
                  {/* here we can put the route for page */}
                  <Route path="/" element={<Home />} />
                  {checkDateRegister && (
                    <>
                      {/* <Route path="contest" element={<ContestChoice />} /> */}
                      <Route path="login" element={<Login />} />
                      {/* <Route path="register" element={<TeamRegister />} /> */}
                      <Route path="confirmation/:token" element={<Verification />} />
                      <Route path="resetpassword/:token" element={<ResetPassword />} />
                      <Route path="forgotpassword" element={<ForgotPassword />} />
                      <Route path="team-profile" element={<TeamProfile />} />
                      <Route
                        path="countdownhack"
                        element={<Timer deadline="2024-04-07T11:00:00" text="Hackathon" />}
                      />
                      <Route
                        path="countdowngame"
                        element={<Timer deadline="2024-04-07T13:00:00" text="Gamejam" />}
                      />
                      {/* <Route path="team-register" element={<TeamRegister />} /> */}
                    </>
                  )}
                  <Route path="*" element={<NotFound />} />
                </Routes>
                {!url.includes('countdown') && <Footer />}
              </Flex>
            </BrowserRouter>
          </>
        ) : (
          <IsComming />
        )}
      </ChakraProvider>
    </QueryClientProvider>
  );
};
