import { Card, CardBody, CardHeader, Flex, Heading, Icon, Text } from '@chakra-ui/react';

import theme from 'src/common/styles/CustomTheme';

import { FaBook } from 'react-icons/fa';

import {
  NoInternetIcon,
  CalendarIcon,
  PeopleTeamIcon,
  BookIcon,
} from 'src/common/utils/icons/IconComponents';
import CustomBadgeContest from './CustomBadgeContest';

export interface ContestCardProps {
  accentColor: string; // e.g. #0977BE
  title: string;
  children: React.ReactNode;
  typeContest: string;
  DateContest: string;
  Domains?: string[];
}

const ContestCard = ({
  accentColor,
  title,
  children,
  typeContest,
  DateContest,
  Domains,
}: ContestCardProps) => {
  return (
    <Card
      // width={'100%'}
      width={{ base: '100%', lg: '90%' }}
      height={'100%'}
      borderRadius={'1em'}
      background={'none'}
      border={`2px solid ${accentColor}`}
    >
      <CardHeader paddingBottom={0}>
        <Heading textAlign={'center'} color={'white'} as="h4" fontSize={'1.5rem'}>
          {title}
        </Heading>
        <Flex
          flexDirection={{ base: 'column', sm: 'row', lg: 'column' }}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <CustomBadgeContest Icon={NoInternetIcon} iconProps={{ color: accentColor }}>
            <Text fontSize={'1rem'}>{typeContest}</Text>
          </CustomBadgeContest>
          <CustomBadgeContest Icon={CalendarIcon} iconProps={{ color: accentColor }}>
            <Text fontSize={'1rem'}>{DateContest}</Text>
          </CustomBadgeContest>
          <CustomBadgeContest Icon={PeopleTeamIcon} iconProps={{ color: accentColor }}>
            <Text fontSize={'1rem'}>Echipe de 3</Text>
          </CustomBadgeContest>
        </Flex>
        <Flex
          flexDirection={{ base: 'column', sm: 'row', lg: 'column' }}
          alignItems={'center'}
          justifyContent={'center'}
        >
          {Domains?.map((domain, index) => (
            <CustomBadgeContest key={index} Icon={BookIcon} iconProps={{ color: accentColor }}>
              <Text fontSize={{ base: '1rem', lg: '1.25rem' }}>{domain}</Text>
            </CustomBadgeContest>
          ))}
        </Flex>
      </CardHeader>

      <CardBody color={theme.colors.gray}>{children}</CardBody>
    </Card>
  );
};

export default ContestCard;
