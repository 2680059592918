import { Center, Flex } from '@chakra-ui/react';

import { DeathRay, MascotOne, MascotTwo, HackLogo } from './Images';

export function Base(props: { children: React.ReactNode }) {
  return (
    <Center mx={'auto'} fontFamily={'Roboto'}>
      <Flex
        pos={'relative'}
        direction={'column'}
        align={'center'}
        alignSelf={'start'}
        maxW={'32rem'}
        w={'100%'}
        my={'auto'}
        gap={{ base: '2rem', md: '3rem' }}
      >
        <MascotOne />

        <MascotTwo />

        <HackLogo />

        {props.children}
      </Flex>
    </Center>
  );
}
