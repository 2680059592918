import { Flex, Heading, VStack } from '@chakra-ui/react';
import AGERPRES from './assets/AGERPRES.png';
import CODETTE from './assets/Codette.png';
import RESTART from './assets/RE-START.png';
import STARTUP from './assets/STARTup.png';
import { PartnerCard } from '../../../../common/components/PartnerCard/PartnerCard';

const partners = [
  {
    fullName: 'Agerpres',
    imgLink: AGERPRES,
    link: 'https://www.agerpres.ro/',
  },
  {
    fullName: 'Codette',
    imgLink: CODETTE,
    link: 'https://codette.ro/',
  },
  {
    fullName: 'RE-START',
    imgLink: RESTART,
    link: 'https://re-start.ro/',
  },
  {
    fullName: 'start#up',
    imgLink: STARTUP,
    link: 'https://start-up.ro/',
  },
];

export const PartnerMedia = () => {
  return (
    <VStack spacing={8}>
      <Heading alignSelf={'flex-start'} size="xl">
        Parteneri Media
      </Heading>
      <Flex gap="1rem" flexWrap={'wrap'} justifyContent={'center'}>
        {partners.map((member, i) => (
          <PartnerCard
            fullName={member.fullName}
            imgLink={member.imgLink}
            link={member.link}
            key={i}
          />
        ))}
      </Flex>
    </VStack>
  );
};
