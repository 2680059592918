import {
  Box,
  HStack,
  Heading,
  Tab,
  TabList,
  TabPanels,
  Tabs,
  VStack,
  Textarea,
  Text,
  Link,
} from '@chakra-ui/react';
import { Formik, Form as FormikForm, Field } from 'formik';
import { ReactElement, useState } from 'react';
import Button from 'src/common/components/Button/Button';
import InputBox from 'src/common/components/InputBox/InputBox';
import { RegisterApi, TeamMember } from 'src/common/services/RegisterApi';
import theme from 'src/common/styles/CustomTheme';
import * as Yup from 'yup';

import PeopleGroup from 'src/common/assets/PeopleGroup.svg';
import Person from 'src/common/assets/Person.svg';
import Univesity from 'src/common/assets/University.svg';
import Phone from 'src/common/assets/Phone.svg';
import Email from 'src/common/assets/Email.svg';
import Lock from 'src/common/assets/Lock.svg';
import Swal from 'sweetalert2';
import Dropdown from 'src/common/components/InputBox/Dropdown';
import HackCheckbox from 'src/common/components/InputBox/HackCheckbox';
import FileInput from 'src/common/components/InputBox/FileInput';
import { TEAMMEMBER_CV_URL } from 'src/common/constants';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

interface TabbedNavigationProps {
  initialValues: any;
  onClose: any;
}

const TabbedNavigation: React.FC<TabbedNavigationProps> = ({ initialValues, onClose }) => {
  // the register steps are the following:
  // 0. register captain
  // 1. register team member 1
  // 2. register team member 2
  // 3. team data
  const [registerStep, setRegisterStep] = useState<number>(0);

  // the team size can be changed to be passed in as a prop if
  // a variable number of members are allowed in a team
  const teamSize = 3;

  const steps = ['Căpitan', 'Coechipier 1', 'Coechipier 2'];
  const navigate = useNavigate();
  return (
    <Tabs variant="none" w="100%">
      <TabList>
        <HStack
          spacing={{
            base: 1,
            md: 4,
          }}
          width="100%"
          justifyContent="center"
        >
          {steps.map((s, i) => (
            <Tab
              key={i}
              onClick={() => setRegisterStep(i)}
              // Style values
              bgColor="transparent"
              border={'1px solid ' + theme.colors.primary}
              borderRadius="0.75rem"
              // Text Values
              fontSize={{ base: '.7rem', md: '.8rem', xl: '1rem' }}
              fontWeight="700"
              textColor={theme.colors.heading}
              // Dimension values
              paddingX={{ base: '1rem', md: '1.5rem', lg: '2rem', xl: '3rem' }}
              // Action values
              _hover={{ bgColor: 'rgba(3, 169, 244, 0.55)' }}
              _selected={{ color: theme.colors.white, bg: theme.colors.hackathon }}
            >
              {s}
            </Tab>
          ))}
        </HStack>
      </TabList>
      <TabPanels padding={'2rem'}>
        <Formik
          initialValues={{
            teamMembers: Array(teamSize).fill({
              firstName: '',
              lastName: '',
              university: '',
              faculty: '',
              email: '',
              telephone: '',
              studentYear: '',
              t_shirts: '',
              check_veg: false,
              allergies: '',
              cv: '',
              gdpr: false,
            }) as TeamMember[],
            teamName: '',
            ...initialValues,
          }}
          validationSchema={Yup.object({
            teamMembers: Yup.array().of(
              Yup.object({
                firstName: Yup.string()
                  .required('Prenumele este obligatoriu')
                  .matches(/^[A-Za-z\s\-']+$/, "Doar litere sau -'"),
                lastName: Yup.string()
                  .required('Numele este obligatoriu')
                  .matches(/^[A-Za-z\s\-']+$/, "Doar litere sau -'"),
                university: Yup.string().required('Universitatea este obligatorie'),
                faculty: Yup.string().required('Facultatea este obligatorie'),
                email: Yup.string()
                  .email('Adresa nu este validă')
                  .required('Email-ul este obligatoriu')
                  .test('is-unique', 'Email-ul este deja folosit', function (value) {
                    if (this.options.context) {
                      const teamMembers = this.options.context.teamMembers as TeamMember[];
                      const emails = teamMembers.map(teamMember => teamMember.email);
                      if (emails.filter(email => email === value).length > 1) {
                        return false;
                      }
                    }
                    return true;
                  }),
                telephone: Yup.string()
                  .matches(/^07[0-9]{8}$/, 'Formatul trebuie să respecte 07xxxxxxxx')
                  .required('Numărul de telefon e obligatoriu')
                  .test('is-unique', 'Numarul de telefon este deja folosit', function (value) {
                    if (this.options.context) {
                      const teamMembers = this.options.context.teamMembers as TeamMember[];
                      const phones = teamMembers.map(teamMember => teamMember.telephone);
                      if (phones.filter(phone => phone === value).length > 1) {
                        return false;
                      }
                    }
                    return true;
                  }),
                studentYear: Yup.string().required('Anul de studiu e obligatoriu'),
                t_shirts: Yup.string().required('Mărimea tricoului e obligatorie'),
                check_veg: Yup.boolean(),
                allergies: Yup.string(),
                cv: Yup.mixed()
                  .test(
                    'size',
                    'CV\u2011ul trebuie să fie sub 2 MB',
                    (val: any) => !val || val?.size < 1_990_000,
                  )
                  .test(
                    'format',
                    'CV\u2011ul trebuie să fie format PDF',
                    (val: any) => !val || val?.type === 'application/pdf',
                  ),
              }),
            ),
            teamName: Yup.string()
              .max(25, 'Numele echipei nu poate avea mai mult de 25 caractere')
              .required('Numele echipei este obligatoriu'),
          })}
          onSubmit={async values => {
            delete values.password;
            delete values.confirmPassword;
            const res = await RegisterApi.registerAndUpdate(values, true);

            // if the token is expired, redirect to login
            if (res.status === 401) {
              localStorage.removeItem('token');
              navigate('/login');
            }

            if (!res.status || res.status > 300) {
              Swal.fire({
                icon: 'error',
                text: res.data,
              });
            } else {
              Swal.fire({
                title: 'Date actualizate cu succes',
                showConfirmButton: true,
                preConfirm: () => window.location.reload(),
              });
            }
            onClose();

            return;
          }}
        >
          {formik => (
            <FormikForm id="teamAccount" style={{ width: '100%' }}>
              {registerStep < teamSize && (
                <Heading
                  // Style values
                  fontFamily="Roboto"
                  textColor={theme.colors.heading}
                  // Text values
                  size={{ base: 'md', md: 'lg' }}
                  marginBottom="1rem"
                >
                  Date {registerStep === 0 ? 'Căpitan' : `Coechipier ${registerStep}`}
                </Heading>
              )}

              <VStack>
                <Box
                  // Position values
                  w={'100%'}
                  margin="auto"
                  display="flex"
                  flexDirection={{ base: 'column', xl: 'row' }}
                  gap={{ base: 0, lg: 12 }}
                  alignItems="top"
                  justifyContent={'space-between'}
                >
                  {registerStep < teamSize ? (
                    <>
                      <VStack w={'100%'}>
                        <InputBox
                          name={`teamMembers.${registerStep}.firstName`}
                          label="Prenume"
                          icon={Person}
                        />
                        <InputBox
                          name={`teamMembers.${registerStep}.lastName`}
                          label="Nume"
                          icon={Person}
                        />
                        <InputBox
                          name={`teamMembers.${registerStep}.university`}
                          label="Universitate"
                          icon={Univesity}
                        />
                        <InputBox
                          name={`teamMembers.${registerStep}.faculty`}
                          label="Facultate"
                          icon={Univesity}
                        />
                        <InputBox
                          name={`teamMembers.${registerStep}.email`}
                          label="Email"
                          icon={Email}
                        />
                        <InputBox
                          name={`teamMembers.${registerStep}.telephone`}
                          label="Telefon"
                          icon={Phone}
                        />
                      </VStack>

                      <VStack alignItems={'left'} padding="1rem" gap={6} w={'100%'}>
                        <Dropdown
                          name={`teamMembers.${registerStep}.studentYear`}
                          label="Anul de studiu"
                        >
                          <option value="" disabled selected hidden>
                            Alege anul de studiu
                          </option>
                          <option value="LICENTA 1">Licenţă I</option>
                          <option value="LICENTA 2">Licenţă II</option>
                          <option value="LICENTA 3">Licenţă III</option>
                          <option value="LICENTA 4">Licenţă IV</option>
                          <option value="MASTER 1">Master I</option>
                          <option value="MASTER 2">Master II</option>
                        </Dropdown>
                        <Dropdown
                          name={`teamMembers.${registerStep}.t_shirts`}
                          label="Mărimea tricoului"
                        >
                          <option value="" disabled selected hidden>
                            Alege mărimea tricoului
                          </option>
                          <option value="XS">XS</option>
                          <option value="S">S</option>
                          <option value="M">M</option>
                          <option value="L">L</option>
                          <option value="XL">XL</option>
                          <option value="XXL">XXL</option>
                        </Dropdown>
                        <HackCheckbox name={`teamMembers.${registerStep}.check_veg`}>
                          Sunt vegetarian
                        </HackCheckbox>
                        <Field
                          as={Textarea}
                          name={`teamMembers.${registerStep}.allergies`}
                          borderColor={theme.colors.primary}
                          placeholder={'Alte alergii sau preferințe alimentare (opţional)'}
                        />
                        <>
                          {/*
                        trebuie sa tinem toate campurile de file input
                        in DOM, pt ca altfel nu ai avea cum sa reinitializezi
                        campul cu fisierul pe care l-a incarcat un utilizator
                        cand ar apasa inapoi (datele fisierului sunt
                        tinute minte in Formik, dar nu ai cum sa ii afisezi
                        utilizatorului numele fisierului corect)

                        asa ca le ascundem pe cele care nu sunt necesare la
                        pasul curent
                      */}
                          {formik.values.teamMembers.map((obj: any, idx: number) => (
                            <div key={idx} hidden={idx !== registerStep}>
                              <FileInput
                                name={`teamMembers.${idx}.cv`}
                                label={'Încarcă CV\u2011ul'}
                                accept="application/pdf"
                              />

                              <Text textColor="white" fontSize={'large'} opacity={'80%'} mt="1rem">
                                {'CV\u2011ul încărcat este: '}
                                <Link
                                  onClick={async () => {
                                    const url = `${process.env.REACT_APP_API_URL}${TEAMMEMBER_CV_URL}/${idx}`;
                                    const res = await axios.get(url, {
                                      responseType: 'blob',
                                      headers: {
                                        Authorization: `Bearer ${localStorage.getItem('token')}`,
                                      },
                                    });

                                    // if the token is expired, redirect to login
                                    if (res.status === 401) {
                                      localStorage.removeItem('token');
                                      navigate('/login');
                                    }

                                    const newUrl = window.URL.createObjectURL(new Blob([res.data]));
                                    const link = document.createElement('a');
                                    link.href = newUrl;
                                    link.setAttribute(
                                      'download',
                                      `${initialValues.teamMembers[idx].cvName?.split('/').pop()}`,
                                    );
                                    document.body.appendChild(link);
                                    link.click();
                                  }}
                                  target="_blank"
                                  rel="noreferrer"
                                  color={'rgba(3, 169, 244, 1)'}
                                >
                                  {initialValues.teamMembers[idx].cvName?.split('/').pop()}
                                </Link>
                              </Text>
                            </div>
                          ))}
                        </>
                      </VStack>
                    </>
                  ) : (
                    <>
                      <VStack>
                        <Heading
                          // Style values
                          fontFamily="Roboto"
                          textColor={theme.colors.heading}
                          // Text values
                          size={{ base: 'md', md: 'lg' }}
                          marginBottom="1rem"
                        >
                          Date echipă
                        </Heading>

                        <InputBox name="teamName" label="Nume echipă" icon={PeopleGroup} />
                      </VStack>
                    </>
                  )}
                </Box>
                <Button
                  // Style values
                  bgColor={theme.colors.hackathon}
                  border={'1px solid ' + theme.colors.primary}
                  borderRadius="0.75rem"
                  // Text values
                  fontSize="medium"
                  fontWeight="700"
                  textColor={theme.colors.white}
                  maxW={'10rem'}
                  margin="auto"
                  marginTop={'2rem'}
                  text="Salvează"
                  // Action values
                  form="teamAccount"
                  type="submit"
                />
              </VStack>
            </FormikForm>
          )}
        </Formik>
      </TabPanels>
    </Tabs>
  );
};

export default TabbedNavigation;
