import { Box, Fade, HStack, Image, Text, VStack, useDisclosure } from '@chakra-ui/react';
import theme from 'src/common/styles/CustomTheme';
import { DownRightArrow, UpRightArrow } from 'src/common/utils/icons/IconComponents';
import Dialog from 'src/common/components/Dialog/Dialog';

interface SponsorCardProps {
  imageSrc: string;
  tipSponsor: string;
  tipEveniment: string;
  descriereSponsor: string;
}

const SponsorCard = ({
  imageSrc,
  tipEveniment,
  tipSponsor,
  descriereSponsor,
}: SponsorCardProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const textLimit = 250;

  return (
    <Box
      w={['100%', 400]}
      p="1.5rem"
      paddingBottom={'5rem'}
      borderRadius={'1rem'}
      border={`2px solid ${theme.colors.primary}`}
      position={'relative'}
      _hover={{ cursor: 'pointer' }}
      onClick={() => {
        if (isOpen) {
          onClose();
        } else {
          onOpen();
        }
      }}
    >
      {/* By default, the image will be shown
        When the user presses the button, we fade out the image and fade in the text 
      */}

      {/* unMountOnExit is true here so the user can easily select the text after the fade */}
      <Fade in={!isOpen} unmountOnExit={true}>
        <Image
          borderRadius={'5rem'}
          position={'absolute'}
          left={'50%'}
          top={'50%'}
          transform={'translate(-50%, -50%)'}
          paddingX={'1rem'}
          src={imageSrc}
        />

        <Box position={'absolute'} bottom={'1rem'} right={'1rem'}>
          <DownRightArrow boxSize={5} />
        </Box>
      </Fade>

      {/* unMountOnExit here is false such that the size of the box is determined by the (hidden) components below */}
      <Fade in={isOpen} unmountOnExit={false}>
        <VStack fontFamily={theme.styles.global.fonts.roboto}>
          <HStack justify={'space-between'} width={'100%'}>
            <Text fontSize={'xl'}>Sponsor {tipSponsor}</Text>
            <Text
              fontSize={'xl'}
              color={theme.colors.gray}
              display={tipSponsor === 'Recruiter' ? 'none' : 'block'}
            >
              {tipEveniment}
            </Text>
          </HStack>
          <Text color={theme.colors.gray} fontSize={'1rem'} whiteSpace="pre-line">
            {descriereSponsor.length >= textLimit
              ? descriereSponsor.substring(0, textLimit) + '\u2026'
              : descriereSponsor}
          </Text>
          {descriereSponsor.length >= textLimit && (
            <Dialog text={descriereSponsor} canClick={isOpen} />
          )}
        </VStack>
        <Image
          position={'absolute'}
          borderRadius={'1rem'}
          height={75}
          bottom={'0'}
          src={imageSrc}
        />

        <Box position={'absolute'} bottom={'1rem'} right={'1rem'}>
          <UpRightArrow boxSize={5} />
        </Box>
      </Fade>
    </Box>
  );
};

export default SponsorCard;
