import {
  PROFILE_URL,
  REGISTER_URL,
  UPDATE_PREFERENCES_URL,
  UPDATE_PROFILE_URL,
  VERIFICATION_URL,
} from '../constants';
import Utils from '../utils';

export interface TeamMember {
  firstName: string;
  lastName: string;
  university: string;
  faculty: string;
  email: string;
  telephone: string;
  studentYear: string;
  t_shirts: string;
  check_veg: boolean;
  allergies: string;
  cv: Blob;
}

export interface RegisterValues {
  teamName: string;
  password: string;
  teamMembers: TeamMember[];
  preferences: string[];
}

function flatten(obj: TeamMember[], key: keyof TeamMember) {
  return obj.reduce((acc, curr) => {
    acc.push(curr[key] as any);
    return acc;
  }, [] as any[]);
}

export const RegisterApi = {
  // update flag is flaky
  registerAndUpdate: async (data: RegisterValues, update?: boolean) => {
    const team = {
      firstName: flatten(data.teamMembers, 'firstName'),
      lastName: flatten(data.teamMembers, 'lastName'),
      email: flatten(data.teamMembers, 'email'),
      telephone: flatten(data.teamMembers, 'telephone'),
      university: flatten(data.teamMembers, 'university'),
      studentYear: flatten(data.teamMembers, 'studentYear'),
      t_shirts: flatten(data.teamMembers, 't_shirts'),
      faculty: flatten(data.teamMembers, 'faculty'),
      check_veg: flatten(data.teamMembers, 'check_veg'),
      allergies: flatten(data.teamMembers, 'allergies'),

      teamName: data.teamName,
      password: data.password,
      competition: localStorage.getItem('competition') || '',
      prefferences: data.preferences,
    };

    const body = new FormData();

    for (const [key, value] of Object.entries(team)) {
      if (Array.isArray(value)) {
        for (const elem of value) {
          body.append(`${key}[]`, elem);
        }
      } else {
        body.append(key, value);
      }
    }

    body.append('captainCV', data.teamMembers[0].cv);
    for (let i = 1; i < data.teamMembers.length; i++) {
      body.append(`CV${i + 1}`, data.teamMembers[i].cv);
    }

    if (update) {
      return Utils.PatchRequest(UPDATE_PROFILE_URL, body, {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      });
    } else {
      return Utils.PostRequest(REGISTER_URL, body);
    }
  },

  confirmAccount: async (token: string) => {
    return Utils.PostRequest(`${VERIFICATION_URL}`, {
      token,
    });
  },

  getProfile: async () => {
    return Utils.GetRequest(PROFILE_URL, {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    });
  },

  updatePreferences: async (preferences: any) => {
    return Utils.PatchRequest(UPDATE_PREFERENCES_URL, preferences, {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    });
  },
};
