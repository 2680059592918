import React from 'react';
import { Button as ChakraButton, ButtonProps as ChakraButtonProps } from '@chakra-ui/react';

interface ButtonProps extends ChakraButtonProps {
  bgColor: string;
  text: string;
}

const Button: React.FC<ButtonProps> = ({ bgColor, text, ...rest }) => {
  return (
    <ChakraButton bgColor={bgColor} {...rest}>
      {text}
    </ChakraButton>
  );
};

export default Button;
