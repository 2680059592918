import { FieldHookConfig, useField, useFormikContext } from 'formik';
import { Text, FormErrorMessage, Input, HStack, FormControl } from '@chakra-ui/react';
import theme from 'src/common/styles/CustomTheme';

const FileInput = ({ label, ...props }: FieldHookConfig<any> & any) => {
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField({
    ...props,
  });

  const error = meta.touched && meta.error;

  return (
    <div hidden={props.hidden}>
      <HStack>
        <Text textColor={error ? 'red' : 'white'} fontSize={'large'} opacity={'80%'}>
          {label}
        </Text>
        {/* do not add {...field} to input's props, it breaks Formik */}
        <Input
          // w={'auto'}
          variant={'unstyled'}
          id={field.name}
          key={field.name}
          {...props}
          type="file"
          onChange={(event: any) => {
            setFieldValue(field.name, event.currentTarget.files?.[0]);
          }}
        />
      </HStack>

      {error && (
        <Text
          // Text values
          textColor={'red.500'}
          fontSize="0.9rem"
          // Dimension values
          marginTop="2px"
        >
          {meta.error}
        </Text>
      )}
    </div>
  );
};

export default FileInput;
