// const defaultIcon = PeopleGroup;
const defaultMinW = '12rem';
const defaultMaxH = 60;
// const defaultPlaceholderColor = '#c4c4c480';
const defaultPlaceholderColor = 'white';

// Interface with mandatory and optional fields
export interface InputBoxProps {
  name: string;
  label: string;
  icon?: string;
  style?: React.CSSProperties;
  inputType: 'email' | 'password' | 'text';
}

// Optional fields are being set by default
const defaultProps: InputBoxProps = {
  name: 'input',
  label: 'Placeholder',
  inputType: 'text',
};
// Function to retrieve the optional fields
export const getInputBoxProps = (props: Partial<InputBoxProps>): InputBoxProps => ({
  // Mandatory
  ...defaultProps,
  // Optional
  ...props,
});
