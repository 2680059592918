import { Box, VStack, Text, Image, HStack, Link, AspectRatio } from '@chakra-ui/react';
import { FaLinkedin } from 'react-icons/fa';

export interface OrganizingCardProps {
  fullName: string;
  role: string;
  imgLink: string;
  linkedInURL: string;
}

const OrganizingCard = ({ fullName, role, imgLink, linkedInURL }: OrganizingCardProps) => {
  return (
    <VStack spacing={2} w={220}>
      <Box>
        {/* <AspectRatio ratio={4/3} w='auto'> */}

        <Image
          src={imgLink}
          w="100%"
          alt={'Organizing Team Member'}
          borderRadius="16px"
          border={`3px solid #0277BD`}
          objectFit="cover"
          aspectRatio={1}
        />
        {/* </AspectRatio> */}
      </Box>
      <VStack spacing={0}>
        <HStack display="flex" spacing={1} justifyContent="center" alignItems="center">
          <Text noOfLines={1} fontSize={['md', 'lg']} fontWeight="800" textColor="white">
            {fullName}
          </Text>
          <Link href={linkedInURL} isExternal>
            <FaLinkedin width="16px" fill="#0677BE" />
          </Link>
        </HStack>
        <Text fontSize={['xs', 'sm']} fontWeight="600" textColor="primary" textAlign="center">
          {role}
        </Text>
      </VStack>
    </VStack>
  );
};

export default OrganizingCard;
