import {
  Divider,
  Heading,
  IconButton,
  Modal,
  ModalContent,
  ModalOverlay,
  VStack,
} from '@chakra-ui/react';
import TabbedNavigation from './components/TabbedNavigation';
import theme from 'src/common/styles/CustomTheme';
import X from '../components/X';
import { useEffect, useState } from 'react';

interface EditProfileModalProps {
  isOpen: boolean;
  onClose: () => void;
  initialValues: any;
}

const EditProfileModal: React.FC<EditProfileModalProps> = ({ isOpen, onClose, initialValues }) => {
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const [isTablet, setIsTablet] = useState<boolean>(false);

  useEffect(() => {
    const handleResize = () => {
      setIsTablet(window.innerWidth > 730 && window.innerWidth < 1090);
      setIsMobile(window.innerWidth < 730);
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        // Style values
        background={theme.colors.background}
        // Dimension values
        maxWidth={{ base: '30rem', md: '45rem', lg: '50rem', xl: '60rem' }}
        paddingY={'1rem'}
      >
        <IconButton
          // Style values
          aria-label="Închide"
          icon={
            isTablet ? (
              <X size="6" color={theme.colors.heading} />
            ) : isMobile ? (
              <X size="4" color={theme.colors.heading} />
            ) : (
              <X size="9" color={theme.colors.heading} />
            )
          }
          // Align values
          position="absolute"
          right={{ base: '1rem', md: '1.5rem', xl: '2rem' }}
          top={{ base: '.6rem', md: '.8rem', xl: '1.4rem' }}
          // Action values
          onClick={onClose}
        />
        <VStack>
          <Heading
            // Text values
            fontSize={{ base: '1.25rem', md: '1.75rem', xl: '3rem' }}
            textColor={theme.colors.heading}
          >
            Modificare date echipă
          </Heading>

          <Divider color={theme.colors.hackathon} borderWidth="1.5px" marginBottom="1rem" />
          <TabbedNavigation initialValues={initialValues} onClose={onClose} />
        </VStack>
      </ModalContent>
    </Modal>
  );
};

export default EditProfileModal;
