import { Center, Flex, Grid, Heading, Text, VStack } from '@chakra-ui/react';
import ContestCard from './ContestCard';
import { ContestCardProps } from './ContestCard';
import theme from 'src/common/styles/CustomTheme';
import { TEST_DATE } from 'src/common/constants';
import Utils from 'src/common/utils';

const checkDateRegister: boolean = Utils.CheckDate(
  process.env.REACT_APP_REGISTER_DATE?.toString() || TEST_DATE,
);

const ContestsData: Array<ContestCardProps> = [
  {
    accentColor: theme.colors.hackathon,
    title: 'Hackathon',
    DateContest: '6-7 Aprilie',
    typeContest: 'Proba Fizică',
    children: (
      <Flex fontSize={'1rem'} flexDirection={'column'} gap={2} fontWeight={400}>
        <Text>Salutare, inovatori și entuziaști ai tehnologiei!</Text>
        <Text>
          Avem plăcerea să vă anunțăm că se apropie primul Hackathon al noului an - o sărbătoare a
          creativității și a ingineriei software. Vă oferim scena pentru a transforma idei
          îndrăznețe în realitate într-un maraton de 24 de ore, ghidați de o temă unică propusă de
          sponsorii noștri de prestigiu{checkDateRegister && ', ING Hubs și BCR'}.
        </Text>
        <Text>
          Strângeți o echipă de trei, veniți cu energie și deschidere spre nou, și pregătiți-vă să
          intrați în competiție pentru premii atractive și experiențe unice. Este o oportunitate
          perfectă de a vă etala talentul, a colabora și a învăța unii de la alții.
        </Text>
        <Text>
          Înscrieți-vă acum pentru a vă alătura aventurii noastre tehnologice. Așteptăm cu nerăbdare
          să vă vedem soluțiile inovatoare!
        </Text>
      </Flex>
    ),
    Domains: ['BCR: Inteligență Artificială', 'ING HUBS: Sustenabilitate Economică'],
  },
  {
    accentColor: theme.colors.gamejam,
    title: 'Gamejam',
    DateContest: '5-7 Aprilie',
    typeContest: 'Hibrid',
    children: (
      <Flex fontSize={'1rem'} flexDirection={'column'} gap={2} fontWeight={400}>
        <Text>Bun venit în arena creativității digitale!</Text>
        <Text>
          Sunteți gata să vă puneți la încercare măiestria în dezvoltarea de jocuri? Gamejam este
          locul tău! În această probă, veți avea șansa de a crea un joc software complet funcțional
          într-un interval de 48 de ore. Tema jocului va fi aleasă de sponsorul nostru, care este la
          fel de pasionat de gaming ca și voi, Playtika.
        </Text>
        <Text>
          Formați o echipă de trei prieteni cu mintea deschisă și cu degetele gata de codat și
          înscrieți-vă pentru a concura. Este o oportunitate unică de a combina distracția cu
          inovația și de a pune în aplicare ideile voastre geniale.
        </Text>
        <Text>
          Nu ratați șansa de a câștiga premiul cel mare și de a vă arăta talentul în fața unei
          comunități de jucători și dezvoltatori. Înscrierea este deschisă - lăsați jocul să
          vorbească pentru voi!
        </Text>
      </Flex>
    ),
  },
];

const Contests = () => {
  return (
    <VStack>
      <Heading alignSelf={'flex-start'} size="xl">
        Probe
      </Heading>
      {/* <Center>
        <VStack>
          <Heading as="h3" fontSize={'1.5rem'}>
            O nouă ediție
          </Heading>
          <Heading as="h3" fontSize={'1.5rem'}>
            O nouă provocare
          </Heading>
          <Text color={'#C4C4C4'} size="lg">
            Premiul cărei probe vrei să-l cucerești ?
          </Text>
        </VStack>
      </Center> */}
      <Grid
        placeItems={'center'}
        templateColumns={{ base: '1fr', lg: 'repeat(2, 1fr)' }}
        gap={{ base: '1.2rem', lg: '0' }}
      >
        {ContestsData.map((contestCardProps, index) => (
          <ContestCard key={index} {...contestCardProps} />
        ))}
      </Grid>
    </VStack>
  );
};

export default Contests;
