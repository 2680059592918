import { Link as ReactRouterLink } from 'react-router-dom';
import React from 'react';
import {
  Link,
  Flex,
  Box,
  Button,
  useDisclosure,
  Drawer,
  DrawerBody,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
} from '@chakra-ui/react';
import { HamburgerIcon } from '@chakra-ui/icons';

interface INavList {
  name: string;
  link?: string;
}

interface ILinkStyle {
  textDecoration: string;
  fontWeight: string;
  fontSize: string;
}

const MyDrawer = (props: { navList: INavList[]; linkStyle: ILinkStyle }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef(null);

  return (
    <>
      <Button ref={btnRef} onClick={onOpen} bg={'transparent'}>
        <HamburgerIcon w={8} h={8} />
      </Button>
      <Drawer isOpen={isOpen} placement="right" onClose={onClose} finalFocusRef={btnRef}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />

          <DrawerBody bg="background">
            <Box h="2.5rem" />
            <Flex direction="column" gap="1rem" align="center">
              {props.navList.map((item, index) =>
                item.name === 'Log Out' ? (
                  <Button
                    key={index}
                    fontSize={{ base: '2xl' }}
                    _hover={{ textDecor: 'none' }}
                    fontWeight={'400'}
                    onClick={() => {
                      localStorage.removeItem('token');
                      window.location.reload();
                    }}
                  >
                    {item.name}
                  </Button>
                ) : (
                  <Link
                    key={index}
                    style={props.linkStyle}
                    _hover={{ textDecor: 'none' }}
                    href={item.link}
                    onClick={onClose}
                  >
                    {item.name}
                  </Link>
                ),
              )}
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default MyDrawer;
