import { VStack } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import theme from '../../../common/styles/CustomTheme';
import CustomText from './CustomText';

interface DetailsProps {
  teamName?: string;
  captain?: string;
  teammates?: string[];
}

const Details: React.FC<DetailsProps> = ({ teamName, captain, teammates }) => {
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const [isTablet, setIsTablet] = useState<boolean>(false);

  useEffect(() => {
    const handleResize = () => {
      setIsTablet(window.innerWidth > 730 && window.innerWidth < 1090);
      setIsMobile(window.innerWidth < 730);
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <VStack>
      <CustomText
        label="Nume echipă"
        text={teamName || 'Numele echipei'}
        labelStyle={{
          fontFamily: 'Roboto',
          fontSize: '1rem',
          fontWeight: '700',
          color: theme.colors.hackathon,
          opacity: 1,
        }}
        textStyle={{
          fontFamily: 'Roboto',
          fontSize: '1.25rem',
          fontWeight: '700',
          color: theme.colors.hint,
          opacity: 1,
        }}
        boxStyle={{ minWidth: isTablet ? '19rem' : isMobile ? '17rem' : '29rem' }}
      />
      <CustomText
        label="Căpitan"
        text={captain || 'Nume Prenume'}
        labelStyle={{
          fontFamily: 'Roboto',
          fontSize: isMobile ? '.75rem' : '1rem',
          fontWeight: '700',
          color: theme.colors.hackathon,
          opacity: 1,
        }}
        textStyle={{
          fontFamily: 'Roboto',
          fontSize: isMobile ? '1rem' : '1.25rem',
          fontWeight: '700',
          color: theme.colors.hint,
          opacity: 1,
        }}
        boxStyle={{ minWidth: isTablet ? '19rem' : isMobile ? '17rem' : '29rem' }}
      />
      <CustomText
        label="Coechipieri"
        text={teammates?.join(', ') || 'Andrei, Mirel, Veta'}
        labelStyle={{
          fontFamily: 'Roboto',
          fontSize: isMobile ? '.75rem' : '1rem',
          fontWeight: '700',
          color: theme.colors.hackathon,
          opacity: 1,
        }}
        textStyle={{
          fontFamily: 'Roboto',
          fontSize: isMobile ? '1rem' : '1.25rem',
          fontWeight: '700',
          color: theme.colors.hint,
          opacity: 1,
        }}
        boxStyle={{ minWidth: isTablet ? '19rem' : isMobile ? '17rem' : '29rem' }}
      />
    </VStack>
    // <Box>
    //       <InputBox
    //         name="teamName"
    //         label="Nume echipă"
    // 		hint='Numele echipei'
    //         minW={isTablet ? '19rem' : isMobile ? '12rem' : '29rem'}
    //         maxH={isTablet ? 75 : isMobile ? 60 : 85}
    // 		labelStyle={{ fontFamily: 'Roboto', fontSize: isMobile ? '.75rem' : '1rem', fontWeight: '700', color: theme.colors.hackathon, opacity: 1 }}
    // 		inputStyle={{ fontFamily: 'Roboto', fontSize: isMobile ? '1rem' : '2rem', fontWeight: '700', color: theme.colors.hint, opacity: 1 }}
    // 		hintStyle={{ fontFamily: 'Roboto', fontSize: isMobile ? '1rem' : '2rem', fontWeight: '700', color: theme.colors.hint, opacity: 1 }}
    // 	  />
    //       <InputBox
    //         name="captain"
    //         label="Căpitan"
    // 		hint='Nume Prenume'
    //         minW={isTablet ? '19rem' : isMobile ? '12rem' : '29rem'}
    //         maxH={isTablet ? 75 : isMobile ? 60 : 85}
    // 		labelStyle={{ fontFamily: 'Roboto', fontSize: isMobile ? '.75rem' : '1rem', fontWeight: '700', color: theme.colors.hackathon, opacity: 1 }}
    // 		inputStyle={{ fontFamily: 'Roboto', fontSize: isMobile ? '1rem' : '1.25rem', fontWeight: '700', color: theme.colors.hint, opacity: 1 }}
    // 		hintStyle={{ fontFamily: 'Roboto', fontSize: isMobile ? '1rem' : '1.25rem', fontWeight: '700', color: theme.colors.hint, opacity: 1 }}          />
    //       <InputBox
    //         name="teammates"
    //         label="Coechipieri"
    // 		hint='Andrei, Mirel, Veta'
    //         minW={isTablet ? '19rem' : isMobile ? '12rem' : '29rem'}
    //         maxH={isTablet ? 75 : isMobile ? 60 : 85}
    // 		labelStyle={{ fontFamily: 'Roboto', fontSize: isMobile ? '.75rem' : '1rem', fontWeight: '700', color: theme.colors.hackathon, opacity: 1 }}
    // 		inputStyle={{ fontFamily: 'Roboto', fontSize: isMobile ? '1rem' : '1.25rem', fontWeight: '700', color: theme.colors.hint, opacity: 1 }}
    // 		hintStyle={{ fontFamily: 'Roboto', fontSize: isMobile ? '1rem' : '1.25rem', fontWeight: '700', color: theme.colors.hint, opacity: 1 }}		  />
    // </Box>
  );
};

export default Details;
