import { FieldHookConfig, useField } from 'formik';
import { Input, Text, Image, HStack, VStack, Select } from '@chakra-ui/react';
import theme from 'src/common/styles/CustomTheme';

interface DropdownProps {
  label: string;
}

const Dropdown = ({ children, label, ...props }: DropdownProps & FieldHookConfig<any> & any) => {
  const [field, meta] = useField(props);

  const isError = meta.touched && meta.error ? true : false;

  return (
    <div>
      <HStack>
        <Text
          // Text values
          textColor={isError ? theme.colors.error : theme.colors.white}
          fontFamily="Roboto"
          fontSize={'large'}
          opacity={'80%'}
        >
          {label}
        </Text>
        <Select
          minW={{ base: '3rem', md: '10rem' }}
          {...field}
          {...props}
          borderColor={isError ? theme.colors.error : theme.colors.primary}
          borderRadius="90"
        >
          {children}
        </Select>
      </HStack>

      {isError && (
        <Text
          // Text values
          textColor={theme.colors.error}
          fontSize="0.9rem"
          // Dimension values
          marginTop="2px"
        >
          {meta.error}
        </Text>
      )}
    </div>
  );
};

export default Dropdown;
