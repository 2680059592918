import { Box, HStack, Hide, Link, Text } from '@chakra-ui/react';
import { Grid, GridItem } from '@chakra-ui/react';
import video from '../../../../common/assets/videos/VideoPromovare_HackITall.mp4';
import { FaFacebook } from 'react-icons/fa';
import Dialog from 'src/common/components/Dialog/Dialog';
import Poster from './assets/poster.jpg';

const About = () => {
  const text = `Organizat de LSAC (Liga Studenţilor din Facultatea de Automatică şi Calculatoare, Universitatea Politehnica Bucureşti), acest hackathon de mare anvergură urmărește, în special, apropierea dintre studenți și marile companii de profil, facilitând astfel oportunitățile de angajare ale participanților.

    Într-un cadru destins, amical, dar în aceeași măsură antrenant, studenții sunt încurajați să învețe, să pună întrebări, să-și lărgească orizontul de cunoaștere, astfel încât, la finalul celor 24 de ore, să ofere un proiect cu care se vor putea mândri în CV-urile personale și, de ce nu, să câștige premiile puse în joc. Ediția curentă aduce un element nou ce vine să ofere un plus de vizibilitate concurenților, CV-urile acestora fiind parcurse atât de firmele sponsor principal, cât și de firmele recruiter.

    Tema hackathonului este oferită de companiile main, care vor realiza și selecția CV-urilor la încheierea perioadei de înscriere, spre deosebire de firmele recruiter care vor avea acces doar la CV-urile finale din cadrul probei de hackathon alese.

    Este momentul să arăți tot ce ai învățat și să te distrezi într-o nouă aventură palpitantă. Înscrie-te alături de cei mai buni doi camarazi și pornește în marea bătălie a liniilor de cod.`;
  return (
    <Grid
      templateColumns={'repeat(2,1fr)'}
      templateRows={'repeat(2,1fr)'}
      alignContent={'center'}
      alignItems={'center'}
      gap={4}
      display={{ md: 'flex' }}
    >
      <GridItem w={{ md: '50%' }}>
        <Text fontSize="5xl" as="b" noOfLines={[1, 2]}>
          Ce este HackITall
        </Text>
        <Text color={'#0977BE'} fontSize="1.25rem">
          HackITall este un eveniment dedicat studenților pasionați de domeniul ITC, din cadrul
          facultăților cu profil tehnic.
        </Text>

        <Box marginTop={{ sm: 4, lg: 6 }} fontSize="1rem">
          <Text whiteSpace="pre-line" display={{ base: 'none', lg: 'block' }}>
            {text}
          </Text>
          <Hide above="lg">
            <Dialog text={text} canClick={true} />
          </Hide>
        </Box>

        <Link isExternal href="https://www.facebook.com/events/276411408652782/">
          <HStack marginTop="4" marginBottom="4" spacing={1}>
            <FaFacebook color="#0677BE" />
            <Text fontSize={'1rem'} color={'#0677BE'}>
              Fii la curent cu noutățile
            </Text>
          </HStack>
        </Link>
      </GridItem>
      <GridItem w={{ md: '50%' }}>
        <video controls poster={Poster}>
          <source src={video} type="video/mp4" />
        </video>
      </GridItem>
    </Grid>
  );
};

export default About;
