import { Image } from '@chakra-ui/react';

import DEATH_RAY_IMG from 'src/common/assets/login/death-ray.png';
import Mascot1 from 'src/common/assets/mascot-1.png';
import Mascot2 from 'src/common/assets/mascot-2.png';
import HACK_LOGO from 'src/common/assets/hackitall.png';

export const DeathRay = () => (
  <Image
    src={DEATH_RAY_IMG}
    alt="Death Ray"
    display={{ base: 'none', xl: 'block' }}
    blendMode={'color-dodge'}
    pos={'absolute'}
    h={'auto'}
    objectFit={'cover'}
  />
);

export const MascotOne = () => {
  return (
    <Image
      src={Mascot1}
      alt="Mascot 1"
      display={{ base: 'none', xl: 'block' }}
      pos={'absolute'}
      bottom={'10%'}
      left={'-80%'}
      h={'auto'}
      w={'auto'}
      maxH={'20rem'}
      maxW={'100%'}
    />
  );
};

export const MascotTwo = () => {
  return (
    <Image
      src={Mascot2}
      alt="Mascot 2"
      display={{ base: 'none', xl: 'block' }}
      pos={'absolute'}
      top={'10%'}
      right={'-80%'}
      h={'auto'}
      w={'auto'}
      maxH={'20rem'}
      maxW={'100%'}
    />
  );
};

export const HackLogo = () => {
  return (
    <Image
      src={HACK_LOGO}
      alt="Logo"
      maxW={'110%'}
      w={'auto'}
      maxH={'12vh'}
      maxWidth={{ base: '90%', md: '110%' }}
    />
  );
};
