import { Image, Flex, Link, Box, Text, Icon } from '@chakra-ui/react';
import React from 'react';
import { FaFacebook, FaTwitch, FaYoutube, FaInstagram, FaLinkedin } from 'react-icons/fa';

const Footer = () => {
  const year = new Date().getFullYear();

  return (
    <Box>
      <Flex
        w="100%"
        px="6"
        py="5"
        align="center"
        justify="center"
        gap={{ base: '5vw', md: '64px' }}
      >
        <Link href="https://www.facebook.com/LsacBucuresti/" isExternal>
          <Icon as={FaFacebook} boxSize={{ base: '30px', md: '36px' }} />
        </Link>
        <Link href="https://www.youtube.com/user/LSACBucuresti" isExternal>
          <Icon as={FaYoutube} boxSize={{ base: '30px', md: '36px' }} />
        </Link>
        <Link href="https://www.instagram.com/lsacbucuresti/" isExternal>
          <Icon as={FaInstagram} boxSize={{ base: '30px', md: '36px' }} />
        </Link>
        <Link href="https://www.linkedin.com/company/lsac-bucuresti/" isExternal>
          <Icon as={FaLinkedin} boxSize={{ base: '30px', md: '36px' }} />
        </Link>
      </Flex>

      <Text color="#919191" size="2rem" align="center">
        Copyright © LSAC București {year}
      </Text>
    </Box>
  );
};
export default Footer;
