import { Badge, HStack, IconProps } from '@chakra-ui/react';
import React from 'react';

interface CustomBadgeContestProps {
  Icon: React.FC<IconProps>;
  iconProps: IconProps;
  children: React.ReactNode;
}

const CustomBadgeContest = ({ Icon, iconProps, children }: CustomBadgeContestProps) => {
  return (
    <HStack spacing={0}>
      <Icon {...iconProps} />
      <Badge background={'none'} textTransform={'none'} color={iconProps.color}>
        {children}
      </Badge>
    </HStack>
  );
};

export default CustomBadgeContest;
