import { VStack, Text } from '@chakra-ui/react';
import { CSSProperties } from 'react';

interface CustomTextProps {
  label: string;
  text: string;
  labelStyle?: CSSProperties;
  textStyle?: CSSProperties;
  boxStyle?: CSSProperties;
}

const CustomText: React.FC<CustomTextProps> = ({
  label,
  text,
  labelStyle,
  textStyle,
  boxStyle,
}) => (
  <VStack spacing={1} width="100%" style={boxStyle}>
    <Text
      // Text values
      textColor="#c4c4c480"
      opacity="50%"
      fontFamily="Roboto"
      fontSize="0.625rem"
      fontWeight="700"
      // Align values
      textAlign="left"
      // Dimenstion values
      width="100%"
      paddingTop=".5rem"
      // CSS values
      style={labelStyle}
    >
      {label}
    </Text>
    <Text
      // Text values
      textColor={'#FFF'}
      // Dimension values
      width="100%"
      marginTop="-3px"
      marginBottom=".5rem"
      // CSS values
      style={textStyle}
    >
      {text}
    </Text>
  </VStack>
);

export default CustomText;
