import {
  FormControl,
  FormErrorMessage,
  Hide,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from '@chakra-ui/react';

import ArrowDown from 'src/common/assets/login/arrow-down.svg';
import { MenuDrawer } from './MenuDrawer';

export function MenuProfile(props: {
  profile: string;
  profileError: string;
  handleProfileChange: (profile: string) => void;
}) {
  const ProfileOptions = ['Hackathon', 'Gamejam'];

  const { profile, profileError, handleProfileChange } = props;
  return (
    <FormControl isInvalid={profileError ? true : false}>
      <Menu>
        {({ isOpen, onClose }) => (
          <>
            <MenuButton
              type="button"
              p={'8px 16px 8px 32px'}
              fontWeight={600}
              borderColor={'hackathon'}
              borderWidth={1}
              background={'background'}
              _hover={{ bgColor: 'hackathon' }}
              borderRadius={10}
            >
              {profile ? 'Profil ' + profile : 'Alege profil'}
              <Image
                src={ArrowDown}
                alt="Arrow Down"
                display={'inline'}
                ml={2}
                transform={isOpen ? 'rotate(180deg)' : 'rotate(0deg)'}
                transition={'transform 0.3s ease'}
              />
            </MenuButton>

            <FormErrorMessage>{profileError}</FormErrorMessage>

            <Hide below="md">
              <MenuList
                backgroundColor={'transparent'}
                border={'none'}
                transformOrigin={'top !important'}
                rootProps={{
                  w: '80%',
                  margin: '0 auto !important',
                  left: '0 !important',
                  right: '0 !important',
                }}
              >
                {ProfileOptions.map((option, index) => (
                  <MenuItem
                    key={index}
                    backgroundColor={'background'}
                    mb={'0.2rem'}
                    fontWeight={600}
                    borderColor={'hackathon'}
                    borderWidth={1}
                    borderRadius={10}
                    justifyContent={'center'}
                    _hover={{ bgColor: 'hackathon' }}
                    onClick={() => handleProfileChange(option)}
                  >
                    Profil {option}
                  </MenuItem>
                ))}
              </MenuList>
            </Hide>
            <Hide above="md">
              <MenuDrawer
                profileOptions={ProfileOptions}
                handleChange={handleProfileChange}
                isOpen={isOpen}
                onClose={onClose}
              />
            </Hide>
          </>
        )}
      </Menu>
    </FormControl>
  );
}
