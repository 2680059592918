import { FAQ } from './components/FAQ/FAQ';

import Contests from './components/Contests/Contests';
import { Box, Container, Stack } from '@chakra-ui/react';
import Sponsors from './components/Sponsors/Sponsors';
import Contact from './components/Contact/Contact';
import Header from './components/Header';
import About from './components/About/About';
import Organizing from './components/OrganizingTeam/Organizing';
import Utils from 'src/common/utils';
import { TEST_DATE } from 'src/common/constants';
import { useEffect } from 'react';
import { PartnerMedia } from './components/PartnerMedia/PartenerMedia';
import { PartnerFood } from './components/PartnerFood/PartnerFood';

const Home = () => {
  const checkDateRegister: boolean = Utils.CheckDate(
    process.env.REACT_APP_REGISTER_DATE?.toString() || TEST_DATE,
  );
  useEffect(() => {
    const temp = window.location.hash;
    window.location.hash = '';
    window.location.hash = temp;
  });

  useEffect(() => {
    const temp = window.location.hash;
    window.location.hash = '';
    window.location.hash = temp;
  });

  return (
    <Container maxWidth={'container.xl'} py={10}>
      <Stack spacing={20}>
        <Box id="header">
          <Header />
        </Box>
        <Box id="about">
          <About />
        </Box>

        <Box id="contests">
          <Contests />
        </Box>

        <Box id="faq">
          <FAQ />
        </Box>

        {checkDateRegister && (
          <>
            <Box id="sponsors">
              <Sponsors />
            </Box>
            <Box id="media">
              <PartnerMedia />
            </Box>
            <Box id="food&drinks">
              <PartnerFood />
            </Box>
          </>
        )}

        <Box id="team">
          <Organizing />
        </Box>

        <Box id="contact">
          <Contact />
        </Box>
      </Stack>
    </Container>
  );
};

export default Home;
