import React from 'react';
import { Input, Text, Image, HStack, VStack } from '@chakra-ui/react';
import { InputBoxProps, getInputBoxProps } from '../../interfaces/inputBoxProps';
import { useField, useFormikContext } from 'formik';
import theme from 'src/common/styles/CustomTheme';

const InputBox: React.FC<Partial<InputBoxProps>> = custom => {
  // Get the mandatory + optional fields
  const {
    name,
    label,
    icon,
    style, // CSS props
    inputType,
  } = getInputBoxProps(custom);

  const [field, meta] = useField(name);
  const { setFieldValue } = useFormikContext();

  // Error constants
  const isError = meta.touched && meta.error ? true : false;

  return (
    <VStack
      justifyContent="center"
      alignItems={'left'}
      // Dimension values
      marginBottom="0.5rem"
      w={'100%'}
    >
      <HStack
        // Design values
        w={'100%'}
        h={{ base: '50px', md: '55px', lg: '65px' }}
        bg="transparent"
        borderRadius="1rem"
        borderWidth={2}
        borderColor={isError ? theme.colors.error : theme.colors.primary}
        flexShrink="0"
        // CSS values
        style={style}
      >
        <VStack spacing={1} width="100%">
          <Text
            // Text values
            textColor={isError ? theme.colors.error : theme.colors.white}
            opacity="50%"
            fontFamily="Roboto"
            fontSize=".9rem"
            fontWeight="700"
            // Dimenstion values
            width="100%"
            marginLeft="2rem"
            paddingTop=".5rem"
          >
            {label}
          </Text>
          <Input
            // Text values
            textColor={isError ? theme.colors.error : theme.colors.white}
            // Design values
            type={inputType}
            bg="transparent"
            border="none"
            focusBorderColor="transparent"
            // Dimension values
            height="100%"
            width="100%"
            marginTop="-3px"
            marginBottom="0.5rem"
            // Formik values
            {...field}
            onBlur={e => {
              field.onBlur(e);
              setFieldValue(name, e.target.value.trim());
            }}
          />
        </VStack>
        <Image src={icon} bg="transparent" marginEnd="0.625rem" />
      </HStack>

      {/* Cheks if there's validation errors and messages them under the input box. */}
      {isError && (
        <Text
          // Text values
          textColor={theme.colors.error}
          fontSize="0.9rem"
          // Dimension values
          marginTop="2px"
        >
          {meta.error}
        </Text>
      )}
    </VStack>
  );
};

export default InputBox;
