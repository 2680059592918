import { Center, Heading, Image, Text, VStack, useDisclosure, Spinner } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import theme from '../../common/styles/CustomTheme';
import leftRobot from '../../common/assets/leftRobot.svg';
import rightRobot from '../../common/assets/rightRobot.svg';
import Details from './components/Details';
import { Sponsor } from 'src/common/interfaces/sponsor';
import SponsorPreferences from 'src/common/components/SponsorPreferences/SponsorPreferences';
import Title from './components/Title';
import EditProfileModal from './EditProfile/EditProfileModal';
import { useQuery } from 'react-query';
import { RegisterApi } from 'src/common/services/RegisterApi';
import Swal from 'sweetalert2';
import Button from 'src/common/components/Button/Button';

import BCR from 'src/common/assets/custom_bcr.png';
import ING from 'src/common/assets/custom_ing_hubs.png';
import PLAYTIKA from 'src/pages/Home/components/Sponsors/assets/playtika.png';

const TeamProfile: React.FC = () => {
  // !!! When defining a sponsors matrix you have to enter '[]' for the columns that you
  // want but don't enter any sponsor details
  const [sponsorsMatrix, setSponsorsMatrix] = useState<Sponsor[][]>([[], []]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const [isTablet, setIsTablet] = useState<boolean>(false);
  const [isPreferencesChanged, setIsPreferencesChanged] = useState<boolean>(false);
  const [sponsors, setSponsors] = useState<Array<Sponsor>>([]);

  const [contest, setContest] = useState('Hackathon'); //TODO: get contest from context
  const [contestColor, setContestColor] = useState(theme.colors.hackathon); //TODO: get contest color from context
  const [teamName, setTeamName] = useState('Numele echipei'); //TODO: get team name from context
  const [captain, setCaptain] = useState('Nume Prenume'); //TODO: get captain from context
  const [teammates, setTeammates] = useState(['Andrei', 'Mirel', 'Veta']); //TODO: get teammates from context

  useEffect(() => {
    const handleResize = () => {
      setIsTablet(window.innerWidth > 730 && window.innerWidth < 1090);
      setIsMobile(window.innerWidth < 730);
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleModalPop = () => {
    onOpen();
  };

  const [isSubmittingPreferences, setIsSubmittingPreferences] = useState(false);
  const handleUpdateData = async () => {
    // console.log(sponsors); // TODO: update sponsors order and display poppup
    const data = { prefferences: sponsors.map(s => s.name) };
    setIsSubmittingPreferences(true);
    const res = await RegisterApi.updatePreferences(data);
    setIsSubmittingPreferences(false);

    // if the token is expired, redirect to login
    const navigate = useNavigate();
    if (res.status === 401) {
      localStorage.removeItem('token');
      navigate('/login');
    }

    if (res.status > 300) {
      Swal.fire({
        icon: 'error',
        text: res.data,
      });
    } else {
      Swal.fire({
        title: 'Success',
      });
    }
    setIsPreferencesChanged(false);
  };

  const [initialValues, setInitialValues] = useState({});
  const responseToInitialValues = (data: any) => {
    const result: any = {};

    result.teamName = data.teamName;

    result.teamMembers = [];
    for (let i = 0; i < data.firstName.length; i++) {
      result.teamMembers.push({
        firstName: data.firstName[i],
        lastName: data.lastName[i],
        email: data.email[i],
        telephone: data.telephone[i],
        university: data.university[i],
        studentYear: data.studentYear[i],
        t_shirts: data.t_shirts[i],
        faculty: data.faculty[i],
        check_veg: data.check_veg[i],
        allergies: data.allergies[i],
        cvName: data.cv[i],
      });
    }

    return result;
  };

  const { isLoading, isError, data, isSuccess } = useQuery({
    queryKey: 'profile',
    queryFn: RegisterApi.getProfile,
    onSuccess: ({ status, data }) => {
      if (!data) {
        Swal.fire({
          icon: 'error',
          text: 'A picat satelitul, încearcă din nou mai târziu!',
        });
        return;
      }

      setInitialValues(responseToInitialValues(data));

      setContest(data.competition);
      setContestColor(theme.colors[data.competition.toLowerCase()]);
      setTeamName(data.teamName);
      setCaptain(`${data.firstName[0]} ${data.lastName[0]}`);

      // need to iterator through both firstName and lastName
      const newTeammates = [];
      for (let i = 1; i < data.firstName.length; i++) {
        newTeammates.push(`${data.firstName[i]} ${data.lastName[i]}`);
      }
      setTeammates(newTeammates);

      const originalSponsors: Sponsor[] =
        data.competition.toLowerCase() === 'hackathon'
          ? [
              { id: 1, name: 'ING Hub', imageSRC: ING },
              { id: 2, name: 'BCR', imageSRC: BCR },
            ]
          : [{ id: 1, name: 'Playtika', imageSRC: PLAYTIKA }];

      setSponsors(data.prefferences.map((p: any) => originalSponsors.find(s => s.name === p)));
      setSponsorsMatrix([
        originalSponsors.filter(s => !data.prefferences.find((p: any) => s.name === p)),
        data.prefferences.map((p: any) => originalSponsors.find(s => s.name === p)),
      ]);
    },
  });

  return (
    <Center display="flex">
      <VStack
        // Style values
        fill="linear-gradient(180deg, rgba(0, 0, 0, 0.03) -4.14%, rgba(216, 216, 216, 0.06) 95.85%, rgba(255, 255, 255, 0.07) 95.86%, rgba(0, 0, 0, 0.07) 95.86%)"
        boxShadow={'inset 0 0 0 0.25rem ' + theme.colors.hackathon} // Stroke effect
        borderRadius="2rem"
        filter="drop-shadow(0rem 0.25rem 0.25rem rgba(0, 0, 0, 0.25))"
        backdropFilter="blur(1rem)"
        // Dimension values
        position="relative"
        spacing={35}
        marginY="1rem"
        paddingY="2.5rem"
        paddingX={{ base: '5%', md: '10%' }}
      >
        <Image
          src={leftRobot}
          zIndex={1}
          width={{ base: '0', xl: '20%' }}
          // Align values
          position="absolute"
          left="-7.5rem"
          bottom="-3rem"
        />
        <Image
          src={rightRobot}
          zIndex={1}
          width={{ base: '0', xl: '20%' }}
          // Align values
          position="absolute"
          right="-7.5rem"
          top="0rem"
        />

        <Title contest={contest} contestColor={contestColor} textColor={theme.colors.heading} />
        <Center
          // Position values
          flexDirection={{ base: 'column', md: 'row' }}
          // Dimension values
          gap={12}
        >
          <Details teamName={teamName} captain={captain} teammates={teammates} />
          <VStack spacing={{ base: 10, md: 35, xl: 12 }}>
            <Heading
              // Style values
              fontFamily="Roboto"
              textColor={theme.colors.hint}
              // Text values
              textAlign="center"
              size={{ base: 'md', md: 'lg' }}
              // Dimension values
              marginBottom={{ base: '.5rem', md: '1rem' }}
            >
              Preferințele probei
            </Heading>
            <VStack gap={8}>
              <SponsorPreferences
                sponsors={sponsorsMatrix}
                setSponsors={setSponsorsMatrix}
                sponsorMinHeight={isTablet ? 90 : isMobile ? 85 : 100}
                sponsorMinWidth={isTablet ? 125 : isMobile ? 115 : 145}
                messages={['Opțiuni', 'Ordine preferințe']}
                updateSponsors={position => setSponsors(position)}
                setIsPreferencesChanged={setIsPreferencesChanged}
                style={{ marginTop: isTablet ? '1rem' : '-0.75rem' }}
                imageStyle={{
                  borderRadius: '1rem',
                }}
              />
              <VStack>
                <Text
                  // Text values
                  textColor={theme.colors.error}
                  fontSize="0.9rem"
                  // Dimension values
                  marginTop="2px"
                  hidden={!isSuccess || sponsors.length !== 0}
                >
                  Cel puţin un sponsor trebuie să fie selectat
                </Text>
                {isSubmittingPreferences ? (
                  <Spinner size={'lg'} />
                ) : (
                  <Button
                    // Style values
                    bgColor={theme.colors.hackathon}
                    border={'1px solid ' + theme.colors.primary}
                    borderRadius="0.75rem"
                    // Text values
                    fontSize={{ base: '.7rem', md: 'small' }}
                    textColor={theme.colors.white}
                    text="Salvează preferințele"
                    // Action values
                    onClick={() => handleUpdateData()}
                    isDisabled={!isPreferencesChanged || sponsors.length === 0}
                  />
                )}
              </VStack>
            </VStack>
          </VStack>
        </Center>

        <VStack spacing={4}>
          <Text
            // Text values
            textAlign="center"
            textColor={theme.colors.gray}
            fontSize=".75rem"
            // Dimension values
            width="55%"
          >
            Dacă au apărut modificări poți actualiza datele echipei.
          </Text>

          <Button
            // Style values
            bgColor="transparent"
            border={'1px solid ' + theme.colors.primary}
            borderRadius="0.75rem"
            // Text values
            fontSize={{ base: '.7rem', md: 'small' }}
            textColor={theme.colors.white}
            text="Actualizează datele"
            // Action values
            onClick={() => handleModalPop()}
          />
        </VStack>
        <EditProfileModal isOpen={isOpen} onClose={onClose} initialValues={initialValues} />
      </VStack>
    </Center>
  );
};

export default TeamProfile;
