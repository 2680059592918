import { Global } from '@emotion/react';

const Fonts = () => (
  <Global
    styles={`
    @font-face {
        font-family: 'Mukta';
        font-weight: 400;
        font-style: normal;
        font-display: swap;
    },
    @font-face {
      font-family: 'b Biger Over';
      src: url('./fonts/bBigerOver.otf') format('otf') url('./fonts/bBigerOver.ttf') format('ttf');
      font-style: normal;
      font-weight: normal;
      font-display: swap;
  }
      `}
  />
);

export default Fonts;
