import React from 'react';
import { Image, Text, VStack, Container, Box } from '@chakra-ui/react';
import Button from '../../../common/components/Button/Button';
import Logo_HackITall from '../../../common/assets/logo_hack.svg';
import mascot_left from '../../../common/assets/header_left_mascot.svg';
import mascot_right from '../../../common/assets/header_right_mascot.svg';
import Utils from 'src/common/utils';
import { TEST_DATE } from 'src/common/constants';
import { Link } from 'react-router-dom';

const Header = () => {
  const checkDate: boolean = Utils.CheckDate(
    process.env.REACT_APP_REGISTER_DATE?.toString() || TEST_DATE,
  );

  return (
    <Box paddingY={{ base: '0.5rem', lg: '5rem' }} justifyContent="center" position={'relative'}>
      <Container textAlign="center">
        <VStack spacing="8px" align="center">
          <Image src={Logo_HackITall} width="700px" height="164px" />
          <Text fontSize={{ base: '2rem', md: '3rem' }} color="heading" fontWeight="800">
            Ne vedem pe 5-7 aprilie
          </Text>
          {/* <VStack>
            <Text fontSize={{ base: '1rem', md: '1.5rem' }} color="gray" fontWeight="400">
              Inscrie-te la o probă de hackathon și arată-ne aptitudinile tale!
            </Text>
          </VStack> */}
          {/* {checkDate && (
            <Link to="/contest">
              <Button
                bgColor="hackathon"
                text="Înscrie-te acum"
                size="lg"
                padding="8"
                marginTop={4}
              />
            </Link>
          )} */}
        </VStack>
      </Container>
      <Box>
        <Image
          src={mascot_left}
          position="absolute"
          left="-4"
          bottom="0"
          zIndex="-20"
          width={{ base: '0', xl: '350px' }}
        />
        <Image
          src={mascot_right}
          position="absolute"
          right="-4"
          bottom="200"
          zIndex="-20"
          width={{ base: '0', xl: '350px' }}
        />
      </Box>
    </Box>
  );
};

export default Header;
