import { Box, Heading, VStack } from '@chakra-ui/react';
import { ContactForm } from './ContactForm';

export default function Contact() {
  return (
    <VStack spacing={10}>
      <Heading alignSelf={'flex-start'} size="xl">
        Contact
      </Heading>
      <Box
        w={['100%', '80%']}
        mx="auto"
        border="2px solid"
        borderColor="#BB00FF"
        p="4"
        borderRadius="1rem"
        pt="40px"
        pb="40px"
      >
        <Box w="90%" mx="auto">
          <ContactForm />
        </Box>
      </Box>
    </VStack>
  );
}
