import {
  Center,
  HStack,
  Heading,
  Hide,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Image,
  VStack,
} from '@chakra-ui/react';
import { useState } from 'react';
import Button from 'src/common/components/Button/Button';
import { FAQAccordion } from './FAQAccordion';
import faqData from './FAQData.json';
import ArrowDown from 'src/common/assets/login/arrow-down.svg';

const FAQButtons = ['General', 'Hackathon', 'Gamejam'];

export function FAQ() {
  const [activeButton, setActiveButton] = useState(FAQButtons[0]);
  const [indexFAQ, setIndexFAQ] = useState(-1);

  return (
    <VStack spacing={10}>
      <Heading alignSelf={'flex-start'} size="xl">
        FAQ
      </Heading>
      <Center fontSize={'1rem'}>
        {/* Buttons for >Tablet size */}
        <Hide below="md">
          <HStack spacing={{ sm: 2, md: 10, lg: 20 }}>
            {FAQButtons.map((name, index) => (
              <Button
                bgColor={activeButton === name ? 'primary' : 'background'}
                outline={`1px solid #0277BD`}
                paddingX={6}
                onClick={() => {
                  setActiveButton(name);
                  setIndexFAQ(-1);
                }}
                text={name}
                key={index}
              />
            ))}
          </HStack>
        </Hide>
        {/* Buttons for <Tablet size */}
        <Hide above="md">
          <Menu>
            {({ isOpen }) => (
              <>
                <MenuButton
                  type="button"
                  py={2}
                  px={4}
                  fontWeight={600}
                  border={'1px solid #03A9F4'}
                  borderRadius={10}
                >
                  {activeButton}
                  <Image
                    src={ArrowDown}
                    alt="Arrow Down"
                    display={'inline'}
                    ml={'1rem'}
                    transform={isOpen ? 'rotate(180deg)' : 'rotate(0deg)'}
                    transition={'transform 0.3s ease'}
                  />
                </MenuButton>

                <MenuList
                  backgroundColor={'transparent'}
                  border={'none'}
                  transformOrigin={'top !important'}
                  rootProps={{
                    margin: '0 auto !important',
                  }}
                >
                  {FAQButtons.map((option, index) => (
                    <MenuItem
                      key={index}
                      backgroundColor={'background'}
                      mb={'0.2rem'}
                      fontWeight={600}
                      border={'1px solid #03A9F4'}
                      borderRadius={10}
                      justifyContent={'center'}
                      onClick={() => setActiveButton(option)}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </MenuList>
              </>
            )}
          </Menu>
        </Hide>
      </Center>
      <FAQAccordion
        data={faqData[activeButton as keyof typeof faqData]}
        indexState={[indexFAQ, setIndexFAQ]}
      />
    </VStack>
  );
}
