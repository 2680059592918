// TODO constant

export const LOGIN_URL = '/api/teams/login';
export const FORGOT_PASSWORD_URL = '/api/teams/forgotPass';
export const CONTACT_URL = '/api/teams/contact';
export const REGISTER_URL = '/api/teams/register';
export const VERIFY_URL = '/api/verifications/verifyEmail';
export const RESET_PASSOWRD_URL = '/api/verifications/verifyReset';
export const UPDATE_PROFILE_URL = '/api/teams/update';
export const PROFILE_URL = '/api/teams/myTeam';
export const VERIFICATION_URL = '/api/verifications/verifyEmail';
export const TEAMMEMBER_CV_URL = '/api/teams/downloadCV';
export const UPDATE_PREFERENCES_URL = '/api/dragAndDrop';
export const TEST_DATE = '2024-01-10T20:00:00';
