import React from 'react';
import OrganizingCard from './OrganizingCard';
import { Flex, Heading, VStack } from '@chakra-ui/react';
import alex from './assets/Alex.jpg';
import alexandra from './assets/Alexandra.jpg';
import costin from './assets/Costin.jpg';
import cristina from './assets/Cristina.jpg';
import dana from './assets/Dana.jpg';
import dani from './assets/Dani.jpg';
import diana from './assets/Diana.jpg';
import daria from './assets/Daria.jpg';
import dayana from './assets/Dayana.jpg';
import ionut from './assets/Ionut.jpg';
import mari from './assets/Mari.jpg';
import maria from './assets/Maria.jpg';

const orgTeam = [
  {
    fullName: 'Dana Ilie',
    role: 'Manager de Proiect',
    imgLink: dana,
    linkedInURL: 'https://www.linkedin.com/in/dana-ilie/',
  },
  {
    fullName: 'Maria Cristina Brînză',
    role: 'Manager Design',
    imgLink: maria,
    linkedInURL: 'https://www.linkedin.com/in/maria-cristina-brinza-5b21aa209/',
  },
  {
    fullName: 'Diana Tudor',
    role: 'Manager FR',
    imgLink: diana,
    linkedInURL: 'https://www.linkedin.com/in/diana-tudor-040899212/',
  },
  {
    fullName: 'Alex Gălățeanu',
    role: 'Manager HR',
    imgLink: alex,
    linkedInURL: 'https://www.linkedin.com/in/alexgalateanu/',
  },
  {
    fullName: 'Ionuț Găină',
    role: 'Manager IT',
    imgLink: ionut,
    linkedInURL: 'https://www.linkedin.com/in/ionutgaina/',
  },
  {
    fullName: 'Alexandra Popescu',
    role: 'Manager Logistică',
    imgLink: alexandra,
    linkedInURL: 'https://www.linkedin.com/in/alexandra-popescu-a206a0296',
  },
  {
    fullName: 'Daria Mihaela Cîrstea',
    role: 'Manager Multimedia',
    imgLink: daria,
    linkedInURL: 'https://www.linkedin.com/in/daria-cirstea-05b150240/',
  },
  {
    fullName: 'Dan Cartale',
    role: 'Manager PR',
    imgLink: dani,
    linkedInURL: 'https://www.linkedin.com/in/dan-cartale-004012206/',
  },
  {
    fullName: 'Marinela Cotoi',
    role: 'Vice Responsabil',
    imgLink: mari,
    linkedInURL: 'https://www.linkedin.com/in/marinela-cotoi/',
  },
  {
    fullName: 'Cristina Radu',
    role: 'Mentor',
    imgLink: cristina,
    linkedInURL: 'https://www.linkedin.com/in/cristina-andreea-radu-a5003a206/',
  },
  {
    fullName: 'Dayana Dumitrescu',
    role: 'Mentor',
    imgLink: dayana,
    linkedInURL: 'https://www.linkedin.com/in/elena-dayana-dumitrescu-044aab250/',
  },
  {
    fullName: 'Costin Roșu',
    role: 'Mentor',
    imgLink: costin,
    linkedInURL: 'https://www.linkedin.com/in/costin-rosu/',
  },
];

const Organizing = () => {
  return (
    <VStack spacing={8}>
      <Heading alignSelf={'flex-start'} size="xl">
        Echipa de Organizare
      </Heading>
      <Flex gap="1rem" flexWrap={'wrap'} justifyContent={'center'}>
        {orgTeam.map((member, i) => (
          <OrganizingCard
            fullName={member.fullName}
            role={member.role}
            imgLink={member.imgLink}
            linkedInURL={member.linkedInURL}
            key={i}
          />
        ))}
      </Flex>
    </VStack>
  );
};

export default Organizing;
